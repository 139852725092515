import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Text, Group, Loader, ActionIcon, Tooltip } from '@mantine/core';
import { IconArrowUp, IconCopy, IconCheck } from '@tabler/icons-react';
import styled from 'styled-components';
import { fonts } from '../themes';

const ScrollTopButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const ContentContainer = styled.div`
  user-select: none; /* Désactiver la sélection de texte */
  -webkit-user-select: none;
`;

const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HTMLContent = ({ html, fontSize }) => (
  <div
    style={{
      whiteSpace: 'pre-line',
      fontFamily: fonts.body,
      fontSize: `${fontSize}px`,
      color: '#333',
      lineHeight: 1.6,
    }}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

const CodePenalList = (props) => {
  const { law_text, currentData: textData, fontSize } = props;
  const [lawText, setLawText] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const textContainerRef = useRef(null);

  const handleCopy = (index, title, content) => {
    const fullContent = `${law_text}\n${title}\n\n${content}`;
    navigator.clipboard.writeText(fullContent).then(() => {
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 5000);
    });
  };

  const handleScrollToTop = () => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleTitleClick = (article) => {
    if (article?.id_collection) {
      const articleUrl = `/article/${article.id_collection}`;
      window.open(articleUrl, '_blank');
    } else {
      console.error("ID de l'article manquant ou invalide.");
    }
  };

  const renderElement = useCallback((element, index) => {
    const titleStyle = {
      fontFamily: fonts.body,
      color: '#0056b3',
      marginBottom: '10px',
    };

    switch (element.type) {
      case 'Partie':
        return (
          <div key={element.titre} id={`index-${index}`} style={{ marginBottom: '15px' }}>
            <h2 style={{ ...titleStyle, fontSize: `${fontSize + 4}px` }}>{element.titre}</h2>
          </div>
        );
      case 'Livre':
        return (
          <div key={element.titre} id={`index-${index}`} style={{ marginBottom: '15px' }}>
            <h3 style={{ ...titleStyle, fontSize: `${fontSize + 3}px` }}>{element.titre}</h3>
          </div>
        );
      case 'Titre':
        return (
          <div key={element.titre} id={`index-${index}`} style={{ marginBottom: '10px' }}>
            <h4 style={{ ...titleStyle, fontSize: `${fontSize + 2}px` }}>{element.titre}</h4>
          </div>
        );
      case 'Chapitre':
        return (
          <div key={element.titre} id={`index-${index}`} style={{ marginBottom: '10px' }}>
            <h5 style={{ ...titleStyle, fontSize: `${fontSize + 1}px` }}>{element.titre}</h5>
          </div>
        );
      case 'Section':
        return (
          <div key={element.titre} id={`index-${index}`} style={{ marginBottom: '8px' }}>
            <h6 style={{ ...titleStyle, fontSize: `${fontSize}px` }}>{element.titre}</h6>
          </div>
        );
      case 'Preambule':
        return (
          <div key={element.titre} id={`index-${index}`} style={{ marginBottom: '20px' }}>
            <CopyContainer>
              <h3 style={{ ...titleStyle, fontSize: `${fontSize + 2}px` }}>{element.titre}</h3>
              <Tooltip label="Copier le préambule">
                <ActionIcon
                  onClick={() => handleCopy(index, element.titre, element.corpus)}
                  color={copiedIndex === index ? 'green' : 'blue'}
                >
                  {copiedIndex === index ? <IconCheck size={18} /> : <IconCopy size={18} />}
                </ActionIcon>
              </Tooltip>
            </CopyContainer>
            <Text ta={'left'} size="sm" style={{ fontSize: `${fontSize}px`, color: '#333' }}>
              <HTMLContent html={element.corpus} fontSize={fontSize} />
            </Text>
          </div>
        );
      case 'Article':
        return (
          <div key={element.titre} style={{ marginBottom: '15px' }}>
            <CopyContainer>
              <Text
                ta={'left'}
                style={{
                  fontFamily: fonts.body,
                  fontSize: `${fontSize}px`,
                  cursor: 'pointer',
                  color: '#007bff',
                }}
                onClick={() => handleTitleClick(element)}
              >
                {element.titre}
              </Text>
              <Tooltip label="Copier l'article">
                <ActionIcon
                  onClick={() => handleCopy(index, element.titre, element.corpus)}
                  color={copiedIndex === index ? 'green' : 'blue'}
                >
                  {copiedIndex === index ? <IconCheck size={18} /> : <IconCopy size={18} />}
                </ActionIcon>
              </Tooltip>
            </CopyContainer>
            <Text
              ta={'left'}
              style={{
                marginTop: '10px',
                fontSize: `${fontSize}px`,
                color: '#333',
                lineHeight: 1.6,
              }}
            >
              <HTMLContent html={element.corpus} fontSize={fontSize} />
            </Text>
          </div>
        );
      default:
        return null;
    }// eslint-disable-next-line
  }, [fontSize, copiedIndex]);

  const fetchData = useCallback((props) => {
    const transformDoc = (data) => (
      <div key="law-text">
        {data.map((element, index) => renderElement(element, index))}
      </div>
    );

    try {
      const text = transformDoc(props);
      if (text) {
        setLawText(text);
      } else {
        console.log('Le document n\'existe pas.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du document :', error);
    }
  }, [renderElement]);

  useEffect(() => {
    fetchData(textData);
  }, [textData, fetchData]);

  return (
    <ContentContainer style={{ position: 'relative' }}>
      <div
        ref={textContainerRef}
        style={{
          maxHeight: '80vh',
          overflowY: 'scroll',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#f8f9fa',
          fontSize: `${fontSize}px`,
        }}
      >
        {lawText ? (
          lawText
        ) : (
          <Group mt="md" mb="xs" justify="center">
            <Loader color="blue" size="xl" type="dots" />
          </Group>
        )}
      </div>

      <ScrollTopButton onClick={handleScrollToTop}>
        <IconArrowUp size={20} />
      </ScrollTopButton>
    </ContentContainer>
  );
};

export default CodePenalList;
