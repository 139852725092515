import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import MyNavbar from '../../component/navbar/Navbar';
import { AppShell, Group, Burger, Slider, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavbarNested } from '../../component/verticalNavbar/NavbarNested';
import { CodeContentPage } from '../../component/lawsContent';
import { IconArrowLeft } from '@tabler/icons-react';

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.3rem;

  @media (max-width: 768px) {
    align-items: stretch;
    margin-top: 1rem;
  }
`;

const StyledSlider = styled(Slider)`
  width: 120px;

  .mantine-Slider-mark {
    display: none; /* Cacher les marques */
  }

  .mantine-Slider-thumb {
    width: 10px;
    height: 10px;
    background-color: #0056b3;
  }

  .mantine-Slider-bar {
    background-color: #0056b3;
  }
`;

// Lien interactif pour le retour
const BackLink = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #0056b3; /* Couleur bleue */
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;

  &:hover {
    text-decoration: underline; /* Soulignement au survol */
    color: #003d99; /* Couleur légèrement plus foncée */
  }

  .icon {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out; /* Animation fluide */
  }

  &:hover .icon {
    transform: translateX(-5px); /* Déplacement de la flèche vers la gauche au survol */
  }
`;

export const LawPageWithURL = () => {
  // Récupérer les paramètres de l'URL
  const { type, text } = useParams();

  // Navigation pour rediriger
  const navigate = useNavigate();

  // État pour la taille du texte
  const [fontSize, setFontSize] = useState(16);

  // Contrôle de l'ouverture du menu latéral
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      {/* Barre supérieure */}
      <AppShell.Header>
        <Group h="100%" w="100%">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <MyNavbar />
        </Group>
      </AppShell.Header>

      {/* Barre latérale */}
      <AppShell.Navbar p="md" style={{ backgroundColor: '#F5F5F5' }}>
        <NavbarNested />
      </AppShell.Navbar>

      {/* Contenu principal */}
      <AppShell.Main>
        {/* Lien Retour */}
        <BackLink onClick={() => navigate(-1)}>
          <IconArrowLeft className="icon" size={20} strokeWidth={2} /> Retour vers texte
        </BackLink>

        {/* Sélection de la taille des caractères */}
        {text && (
          <SliderContainer>
            <Text size="xs" weight={500} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              Taille du texte : {fontSize}px
            </Text>
            <StyledSlider
              value={fontSize}
              onChange={setFontSize}
              min={12}
              max={30}
              step={1}
            />
          </SliderContainer>
        )}

        {/* Affichage des données */}
        <div>
          {type && text ? (
            // Passer directement les paramètres de l'URL au composant CodeContentPage
            <CodeContentPage
              texttype={type}
              currentId={text}
              fontSize={fontSize}
            />
          ) : (
            // Afficher un message ou un indicateur de chargement si les données sont manquantes
            <Group mt="md" mb="xs" justify="center">
              <Text color="dimmed">Aucune donnée disponible</Text>
            </Group>
          )}
        </div>
      </AppShell.Main>
    </AppShell>
  );
};
