import React, { useState } from 'react';
import {
  Text,
  Title,
  Stack,
  Button,
  Group,
  Badge,
  Tabs,
  Box,
  Textarea,
  Divider,
} from '@mantine/core';
import { IconFileText, IconFileAnalytics } from '@tabler/icons-react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase-config';
import { notifications } from '@mantine/notifications';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useGetUserInfo } from '../../../hooks/useGetUserInfo';
import { useParams } from 'react-router-dom';

const StyledMarkdown = styled.div`
  text-align: left;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  ul, ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  code {
    background-color: #f5f5f5;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
  }

  blockquote {
    border-left: 4px solid #e9ecef;
    padding-left: 1rem;
    margin-left: 0;
    color: #666;
  }
`;

const ItemArticle = ({ document }) => {
  const [comment, setComment] = useState(document.comments || '');
  const [isEditing, setIsEditing] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);

  const { userID } = useGetUserInfo();
  const { caseId } = useParams();

  const handleSaveComment = async () => {
    if (!userID || !caseId || !document.id) {
      notifications.show({
        title: 'Erreur',
        message: 'Informations manquantes pour l\'enregistrement',
        color: 'red',
      });
      return;
    }

    try {
      const docRef = doc(db, 'case_folders', userID, 'folders', caseId, 'documents', document.id);
      await updateDoc(docRef, {
        comments: comment,
        lastUpdated: new Date(),
      });

      setIsEditing(false);
      notifications.show({
        title: 'Succès',
        message: 'Commentaire enregistré',
        color: 'green',
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error updating comment:', error);
      notifications.show({
        title: 'Erreur',
        message: 'Erreur lors de l\'enregistrement du commentaire',
        color: 'red',
        autoClose: 3000,
      });
    }
  };

  return (
    <Stack spacing="md" align="flex-start">
      {/* Titre */}
      <Title order={3}>{document.titre}</Title>

      {/* Domaine */}
      {document.domaine && (
        <Group spacing="xs" position="left">
          {document.domaine.map((domain, index) => (
            <Badge key={index} color="blue">{domain}</Badge>
          ))}
        </Group>
      )}

      {/* Contenu principal avec onglets */}
      <Tabs defaultValue="article" style={{ width: '100%' }}>
        <Tabs.List>
          <Tabs.Tab value="article" icon={<IconFileText size={14} />}>
            Texte de l'article
          </Tabs.Tab>
          <Tabs.Tab value="analyse" icon={<IconFileAnalytics size={14} />}>
            Analyse
          </Tabs.Tab>
        </Tabs.List>

        {/* Texte de l'article */}
        <Tabs.Panel value="article" pt="xs">
          <Box sx={{ textAlign: 'left' }}>
            <StyledMarkdown>
              <ReactMarkdown>
                {showFullContent
                  ? document.corpus || 'Aucun texte disponible'
                  : (document.corpus?.split('\n').slice(0, 25).join('\n')) || ''}
              </ReactMarkdown>
            </StyledMarkdown>
            {(document.corpus?.split('\n').length > 25) && (
              <Button
                variant="subtle"
                size="xs"
                onClick={() => setShowFullContent(!showFullContent)}
                mt="sm"
              >
                {showFullContent ? 'Voir moins' : 'Voir plus'}
              </Button>
            )}
          </Box>
        </Tabs.Panel>

        {/* Analyse */}
        <Tabs.Panel value="analyse" pt="xs">
          <Text align="left">{document.law_text || 'Aucune analyse disponible'}</Text>
        </Tabs.Panel>
      </Tabs>

      {/* Commentaires */}
      <Divider />
      <Stack spacing="xs" style={{ width: '100%' }}>
        <Text weight={500}>Commentaires</Text>
        {isEditing ? (
          <>
            <Textarea
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
              minRows={3}
              autosize
              placeholder="Ajoutez votre commentaire ici..."
            />
            <Group position="right">
              <Button 
                variant="outline" 
                onClick={() => {
                  setIsEditing(false);
                  setComment(document.comments || '');
                }}
              >
                Annuler
              </Button>
              <Button 
                onClick={handleSaveComment}
                disabled={!comment.trim()}
              >
                Enregistrer
              </Button>
            </Group>
          </>
        ) : (
          <>
            <Text color={comment ? 'inherit' : 'dimmed'}>
              {comment || 'Aucun commentaire'}
            </Text>
            <Button variant="light" onClick={() => setIsEditing(true)}>
              {comment ? 'Modifier le commentaire' : 'Ajouter un commentaire'}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ItemArticle;
