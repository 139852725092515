import React from 'react';
import { Table, Title, Center } from '@mantine/core';
import {
  IconBook,
  IconGavel,
  IconFileText,
  IconSearch,
  IconRobot,
  IconBell,
  IconListDetails,
  IconMessageCircle,
  IconStar,
  IconFolder,
  IconSchool,
  IconBriefcase
} from '@tabler/icons-react'; // Import des icônes pour les fonctionnalités

const TarifExactComponent = () => {
  const tarifs = [
    {
      offre: 'Étudiant',
      icone: <IconSchool size={24} color="#4dabf7" />,
      prixMensuel: '1,500 FCFA / mois',
      prixAnnuel: '10,000 FCFA / an',
      inclusions: {
        consultationDocuments: true,
        decisionsJustice: true,
        resumeAnalyses: true,
        commentairesDecisions: true,
        instantSearch: true,
        alertes: true,
        docJuridique: true,
        gestionFavoris: false,
        gestionDossiers: false,
        rechercheIA: false,
      },
    },
    {
      offre: 'Pro',
      icone: <IconBriefcase size={24} color="#ff922b" />,
      prixMensuel: '10,000 FCFA / mois',
      prixAnnuel: '70,000 FCFA / an',
      inclusions: {
        consultationDocuments: true,
        decisionsJustice: true,
        resumeAnalyses: true,
        commentairesDecisions: true,
        gestionFavoris: true,
        gestionDossiers: true,
        rechercheIA: true,
        instantSearch: true,
        alertes: true,
        docJuridique: true,
      },
    },
  ];

  const fonctionnalites = [
    { label: 'Lois et Réglements', key: 'consultationDocuments', icon: <IconBook size={20} /> },
    { label: 'Jurisprudence (+15k décisions)', key: 'decisionsJustice', icon: <IconGavel size={20} /> },
    { label: 'Résumés et analyses des décisions', key: 'resumeAnalyses', icon: <IconListDetails size={20} /> },
    { label: 'Commentaires sur les décisions', key: 'commentairesDecisions', icon: <IconMessageCircle size={20} /> },
    { label: 'Documents juridiques (conventions, doctrines...)', key: 'docJuridique', icon: <IconFileText size={20} /> },
    { label: 'Recherche instantanée', key: 'instantSearch', icon: <IconSearch size={20} /> },
    { label: 'Recherche par IA', key: 'rechercheIA', icon: <IconRobot size={20} /> },
    { label: 'Alertes juridiques', key: 'alertes', icon: <IconBell size={20} /> },

    { label: 'Gestion des favoris', key: 'gestionFavoris', icon: <IconStar size={20} /> },
    { label: 'Création et gestion de dossiers juridiques', key: 'gestionDossiers', icon: <IconFolder size={20} /> },
  ];

  return (
    <div style={{ paddingTop:"5%", paddingBottom:"5%", backgroundColor:"#f8f9fa", display:"flex", justifyContent:"center" }}>
      <div style={{ maxWidth:"900px", width:"100%" }}>
        <Center>
          <Title order={2} mb="lg">
            Tarifs
          </Title>
        </Center>
        <Table
          highlightOnHover
          verticalSpacing="md"
          horizontalSpacing="lg"
          withBorder
          withColumnBorders
          style={{ borderRadius:"10px", overflow:"hidden" }}
        >
          <thead style={{ backgroundColor:"#e9ecef" }}>
            <tr>
              <th style={{ textAlign:"left", width:"40%" }}>Fonctionnalités</th>
              {tarifs.map((tarif) => (
                <th
                  key={tarif.offre}
                  style={{
                    textAlign:"center",
                    backgroundColor:
                      tarif.offre === "Étudiant" ? "#dbeafe" : "#ffe4c4",
                  }}
                >
                  {tarif.icone}
                  <div style={{ marginTop:"8px" }}>
                    <strong>{tarif.offre}</strong>
                  </div>
                  <div style={{ fontSize:"14px" }}>
                    {tarif.prixMensuel} / {tarif.prixAnnuel}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fonctionnalites.map((fonctionnalite) => (
              <tr key={fonctionnalite.key}>
                {/* Fonctionnalité */}
                <td style={{ textAlign:"left", fontWeight:"600", display:"flex", alignItems:"center", gap:"10px" }}>
                  {fonctionnalite.icon}
                  {fonctionnalite.label}
                </td>

                {/* Inclus ou non pour chaque offre */}
                {tarifs.map((tarif) => (
                  <td
                    key={`${tarif.offre}-${fonctionnalite.key}`}
                    style={{
                      textAlign:"center",
                      fontSize:"18px",
                      color:
                        tarif.inclusions[fonctionnalite.key] ? "#4caf50" : "#f44336",
                    }}
                  >
                    {tarif.inclusions[fonctionnalite.key] ? "✔️" : "❌"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TarifExactComponent;
