import React, { useState, useEffect } from 'react';
import { useSearchBox, useInstantSearch } from 'react-instantsearch';
import { Input, ActionIcon, Transition, Button, useMantineColorScheme, Loader } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react'; 
import { AiOutlineRobot } from 'react-icons/ai'; 
import { useMediaQuery } from '@mantine/hooks'; 
import styled from 'styled-components';

// Wrapper pour gérer l'expansion responsive
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px;
  max-width: ${(props) => (props.isExpanded ? '100%' : '300px')};
  transition: max-width 0.3s ease;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

// Input avec une expansion dynamique
const CustomInput = styled(Input)`
  transition: width 0.3s ease;
  width: ${(props) => (props.isExpanded ? '100%' : '0')};
  visibility: ${(props) => (props.isExpanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Espacement entre l'icône et le texte */
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: #00aaff; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
  }

  &:active {
    transform: translateY(2px) scale(0.98); 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
  }
`;


const ResponsiveSearchBox = ({ searchInputRef }) => {
  const { query, refine } = useSearchBox(); 
  const { status } = useInstantSearch();
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState(query); 
  const [placeholder, setPlaceholder] = useState("Rechercher des décisions ou poser une question en droit sénégalais ou OHADA...");
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';
  const isSmallScreen = useMediaQuery('(max-width: 765px)');
  const isSearchStalled = status === 'stalled';

  useEffect(() => {
    const intervalId = setInterval(() => {
      const placeholders = [
        "Exemple : 'Code du travail sénégalais 2020'",
        "Exemple : 'Acte uniforme OHADA sur les sociétés commerciales'",
        "Exemple : 'jurisprudence sénégalaise sur le divorce'",
        "Posez une question : 'Quels sont les droits des salariés au Sénégal?'",
        "Exemple : 'juridiction OHADA concernant le droit des affaires'",
        "Exemple : 'loi n°2008-47 sur la sécurité sociale Sénégal'",
      ];
      setPlaceholder((prev) => {
        const currentIndex = placeholders.indexOf(prev);
        return currentIndex === placeholders.length - 1
          ? placeholders[0]
          : placeholders[currentIndex + 1];
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (event) => {
    const value = event.currentTarget.value;
    setInputValue(value);
    refine(value); 
  };

  const handleSummaryClick = () => {
    if (inputValue) {
      searchInputRef.current = inputValue; // Capturer la valeur lors du clic
    }
  };

  const handleReset = () => {
    setInputValue(''); 
    refine(''); 
    searchInputRef.current = ''; // Réinitialiser la valeur dans useRef
  };

  return (
    <SearchWrapper isExpanded={isExpanded}>
      <ActionIcon
        variant="filled"
        color={isDark ? 'yellow' : 'blue'}
        size="lg"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <IconX size={20} /> : <IconSearch size={20} />}
      </ActionIcon>

      <Transition
        mounted={isExpanded}
        transition="scale-y"
        duration={300}
        timingFunction="ease"
      >
        {(styles) => (
          <CustomInput
            id="searchInput"
            style={styles}
            isExpanded={isExpanded}
            placeholder={placeholder} 
            variant="filled"
            radius="md"
            value={inputValue}
            onChange={handleInputChange}
            icon={
              inputValue && !isSearchStalled ? (
                <ActionIcon
                  onClick={handleReset} 
                  variant="transparent"
                  color="gray"
                  size="lg"
                  style={{ marginRight: '8px' }}
                >
                  <IconX size={16} />
                </ActionIcon>
              ) : (
                <IconSearch size={16} />
              )
            }
            rightSection={
              isSearchStalled && <Loader size="xs" />
            }
          />
        )}
      </Transition>

      {isExpanded && (
        <Transition
          mounted={isExpanded}
          transition="scale-y"
          duration={300}
          timingFunction="ease"
        >
          {(styles) => (
            <StyledButton
              onClick={handleSummaryClick}
              style={{ marginLeft: '10px', padding: '4px 12px', fontSize: '12px', ...styles }}
              leftIcon={<AiOutlineRobot size={18} style={{ display: 'block' }} />}
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan' }}
            >
              {isSmallScreen ? <AiOutlineRobot size={18} /> : "Demander à l'IA"}
            </StyledButton>
          )}
        </Transition>
      )}
    </SearchWrapper>
  );
};


export default ResponsiveSearchBox;
