import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Text, Group, Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom'; // Import de useNavigate pour la navigation

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr)); /* Quatre colonnes par défaut */
  gap: 1.5rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(100px, 1fr)); /* Trois colonnes sur tablette */
    gap: 1rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr)); /* Deux colonnes sur mobile */
    gap: 0.75rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, minmax(100px, 1fr)); /* Une colonne sur très petits écrans */
    gap: 0.5rem;
  }
`;

const YearItem = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: #000; /* Texte noir par défaut */
  text-align: left; /* Alignement à gauche */
  padding: 0.5rem;
  cursor: pointer;
  
  transition: transform 0.2s ease, color 0.2s ease;

  &:hover {
    color: #1c7ed6; /* Texte bleu au survol */
    transform: scale(1.05); /* Légère mise en avant */
    text-decoration: underline; /* Soulignement au survol */
  }

  margin-bottom: 0.5rem; /* Espacement entre les lignes */
`;

const YearList = () => {
  const [modalOpen, setModalOpen] = useState(false); // État pour afficher le modal
  const navigate = useNavigate(); // Hook pour la navigation

  // Génération dynamique des années
  const years = Array.from({ length: (2024 - 1986 + 1) }, (_, i) => i + 1986);

  // Gestion du clic sur une année
  const handleItemClick = () => setModalOpen(true);

  return (
    <>
      <GridContainer>
        {years.map((year) => (
          <YearItem key={year} onClick={handleItemClick}>
            {year}
          </YearItem>
        ))}
      </GridContainer>

      {/* Modal pour inviter à se connecter ou s'inscrire */}
      <Modal opened={modalOpen} onClose={() => setModalOpen(false)} centered>
        <Text size="lg" weight={500} style={{ marginBottom: "1rem" }}>
          Veuillez vous connecter ou vous inscrire pour explorer ce contenu.
        </Text>
        <Group position="center">
          <Button variant="light" color="blue" onClick={() => navigate("/login")}>
            Se connecter
          </Button>
          <Button variant="outline" color="blue" onClick={() => navigate("/register")}>
            S'inscrire
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default YearList;
