// src/pages/Favoris/components/TabNavigation.jsx
import React from 'react';
import { Tabs } from '@mantine/core';
import { IconBook, IconGavel } from '@tabler/icons-react';

const TabNavigation = ({ activeTab, onTabChange }) => {
  return (
    <Tabs value={activeTab} onChange={onTabChange}>
      <Tabs.List>
        <Tabs.Tab value="law_text" icon={<IconBook size="0.8rem" />}>
          Lois et Règlements
        </Tabs.Tab>
        <Tabs.Tab value="décisions" icon={<IconGavel size="0.8rem" />}>
          Décisions
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default TabNavigation;
