import React from 'react';
import { Card, Text, Group, Button, Stack, Badge } from '@mantine/core';
import { IconFolder, IconTrash, IconEye } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase-config';
import { useGetUserInfo } from '../../../hooks/useGetUserInfo';
import { notifications } from '@mantine/notifications';

const CaseCard = ({ caseData, onRemove }) => {
  const navigate = useNavigate();
  const { userID } = useGetUserInfo();

  const handleDelete = async () => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce dossier ?')) return;

    try {
      await deleteDoc(doc(db, 'case_folders', userID, 'folders', caseData.id));
      notifications.show({
        title: 'Succès',
        message: 'Dossier supprimé avec succès',
        color: 'green',
      });

      // Appeler la fonction onRemove pour mettre à jour la liste dans le parent
      if (onRemove) onRemove(caseData.id);
    } catch (error) {
      notifications.show({
        title: 'Erreur',
        message: 'Erreur lors de la suppression du dossier',
        color: 'red',
      });
    }
  };

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Stack spacing="xs">
        <Group position="apart">
          <Group>
            <IconFolder size={24} />
            <Text weight={500} size="lg">{caseData.name}</Text>
          </Group>
          <Badge>
            {new Date(caseData.created_at?.seconds * 1000).toLocaleDateString()}
          </Badge>
        </Group>

        <Text size="sm" color="dimmed" lineClamp={2}>
          {caseData.description || 'Aucune description'}
        </Text>

        <Group position="apart" mt="md">
          <Button
            variant="light"
            color="blue"
            onClick={() => navigate(`/dossiers/${caseData.id}`)}
            leftIcon={<IconEye size={16} />}
          >
            Voir
          </Button>
          <Button
            variant="light"
            color="red"
            onClick={handleDelete}
            leftIcon={<IconTrash size={16} />}
          >
            Supprimer
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

export default CaseCard;
