import React from 'react';
import { Text, Group, Badge, Collapse, Box } from '@mantine/core';
import { IconBook } from '@tabler/icons-react';

const FavoriteItemArticle = ({ favorite, searchTerm, expanded, renderTitle }) => (
  <>
    <Group position="apart" style={{ marginBottom: '0.5rem' }}>
      <Group>
        <IconBook size={16} />
        {renderTitle()}
      </Group>
      <Badge color="blue">Loi</Badge>
    </Group>
    <Text size="sm" weight={500} style={{ marginTop: '0.5rem' }}>
      {favorite.law_text}
    </Text>
    <Collapse in={expanded}>
      <Box 
        style={{ marginTop: '0.5rem' }}
        dangerouslySetInnerHTML={{ __html: favorite.corpus }}
      />
    </Collapse>
  </>
);

export default FavoriteItemArticle;
