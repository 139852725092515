import React, { useState } from 'react';
import { Card, Button, Textarea, Group, ActionIcon, Text } from '@mantine/core';
import { IconTrash, IconChevronDown, IconChevronUp, IconClock } from '@tabler/icons-react';
import { updateFavoriteComment, deleteFavorite } from '../favoriteService';
import { useGetUserInfo } from '../../../hooks/useGetUserInfo';
import FavoriteItemArticle from './FavoriteItemArticle';
import FavoriteItemDecision from './FavoriteItemDecision';

const FavoriteItem = ({ favorite, searchTerm, onUpdate, renderTitle }) => {
  const [comment, setComment] = useState(favorite.comments);
  const [expanded, setExpanded] = useState(false);
  const { userID } = useGetUserInfo();

  const handleCommentChange = async () => {
    if (!userID) {
      console.error('User ID not available');
      return;
    }
    await updateFavoriteComment(userID, favorite.type, favorite.docId, comment);
    onUpdate();
  };

  const handleDelete = async () => {
    if (!userID) {
      console.error('User ID not available');
      return;
    }
    await deleteFavorite(userID, favorite.type, favorite.docId);
    onUpdate();
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const ItemComponent = favorite.type === 'law_text' ? FavoriteItemArticle : FavoriteItemDecision;

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder style={{ marginBottom: '1rem' }}>
      <ItemComponent
        favorite={favorite}
        searchTerm={searchTerm}
        expanded={expanded}
        renderTitle={renderTitle}
      />
      <Group position="apart" style={{ marginTop: '0.5rem' }}>
        <Group spacing="xs">
          <IconClock size={16} />
          <Text size="sm" color="dimmed">
            Dernière modification : {formatDate(favorite.timestamp)}
          </Text>
        </Group>
        <ActionIcon onClick={() => setExpanded(!expanded)}>
          {expanded ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
        </ActionIcon>
      </Group>
      <Textarea
        placeholder="Ajouter un commentaire..."
        value={comment}
        onChange={(event) => setComment(event.currentTarget.value)}
        onBlur={handleCommentChange}
        autosize
        minRows={2}
        maxRows={4}
        style={{ marginTop: '0.5rem' }}
      />
      <Group position="right" style={{ marginTop: '0.5rem' }}>
        <Button 
          variant="light" 
          color="red" 
          leftIcon={<IconTrash size={16} />}
          onClick={handleDelete}
        >
          Supprimer
        </Button>
      </Group>
    </Card>
  );
};

export default FavoriteItem;
