import React from 'react';
import styled from 'styled-components';
import { Title, Text, ThemeIcon, Button } from '@mantine/core';
import { IconSearch, IconSettingsAutomation, IconBolt } from '@tabler/icons-react';
import searchInstantVideo from '../../../assets/video/search_instant.MP4'; // Vidéo de démonstration
import PortailLayout from '../../../component/Layout/PortailLayout'; // Layout principal
import { useNavigate } from 'react-router-dom';
import { fonts } from '../../../component/themes';
// Contexte général
const FullWidthBackground = styled.div`
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  padding: 3rem 1rem;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column; /* Vidéo en dessous sur mobile */
    padding: 2rem 1rem;
    gap: 1rem;
  }
`;

// Wrapper pour la vidéo
const ImageWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%; /* Prend toute la largeur */
  max-width: 400px; /* Largeur maximale pour les grands écrans */
  aspect-ratio: 1 / 1; /* Garantit un ratio 1:1 (carré) */
  overflow: hidden; /* Cache les débordements éventuels */
  transform: translate(0, 0); /* Centre l'élément horizontalement */
  z-index: 1;

  video {
    width: 100%; /* Remplit tout le conteneur horizontalement */
    height: 100%; /* Ajuste à la hauteur du conteneur */
    object-fit: cover; /* Remplit le cadre en rognant si nécessaire */
    border-radius: 12px; /* Coins arrondis */
    opacity: 0.5; /* Légère transparence */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1024px) {
    max-width: 100%; /* Vidéo prend toute la largeur disponible */
    transform: none; /* Supprime le décalage sur les écrans plus petits */
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%; /* Toujours 100% pour mobile */
    aspect-ratio: 1 / 1; /* Toujours conserver le ratio 1:1 */
    video {
      opacity: 0.6; /* Légère augmentation de la transparence sur mobile */
    }
  }
`;


// Contenu textuel
const ContentWrapper = styled.div`
  flex: 1;
  max-width: 600px;
  z-index: 2;


  @media (max-width: 1024px) {
    max-width: 100%; /* Texte prend toute la largeur sur mobile */
    text-align: center; /* Centre le texte sur mobile */
  }
`;

const StyledTitle = styled(Title)`
  font-size: 2.5rem;
  font-weight: 700;
  font-family: ${fonts.heading};

  color: #38bdf8;
  margin-bottom: 1rem;
    margin-top: 1rem;


  @media (max-width: 768px) {
    font-size: 2rem; /* Réduction de la taille sur mobile */
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: 1.25rem;
  color: #cbd5e1;
  margin-bottom: 2rem;
  font-family: ${fonts.body};

  @media (max-width: 768px) {
    font-size: 1rem; /* Réduction de la taille sur mobile */
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  p {
    margin: 0;
    font-size: 1rem;
    font-family: ${fonts.body};
    color: #e2e8f0;
    font-weight: 500;
    line-height: 1.5;

    @media (max-width: 768px) {
      font-size: 0.9rem; /* Réduction de la taille sur mobile */
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 1.5rem; /* Réduction de l'espacement sur mobile */
  }
`;

// Composant principal
export function AssistantJuridiquePage() {
  const navigate = useNavigate();

  return (
    <PortailLayout>
      <FullWidthBackground>
        {/* Contenu principal */}
        <ContentWrapper>
          <StyledTitle>Recherche Instantanée</StyledTitle>
          <StyledSubtitle>
            Découvrez une expérience de recherche rapide et intuitive, grâce à la puissance de <strong>Meilisearch</strong>.
          </StyledSubtitle>

          <div>
            <IconTextWrapper>
              <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }}>
                <IconBolt size={24} />
              </ThemeIcon>
              <p>Résultats instantanés dès que vous commencez à taper.</p>
            </IconTextWrapper>

            <IconTextWrapper>
              <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'teal', to: 'lime' }}>
                <IconSettingsAutomation size={24} />
              </ThemeIcon>
              <p>Tri et filtres automatiques pour affiner votre recherche.</p>
            </IconTextWrapper>

            <IconTextWrapper>
              <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'orange', to: 'red' }}>
                <IconSearch size={24} />
              </ThemeIcon>
              <p>Recherche puissante adaptée aux articles juridiques et décisions.</p>
            </IconTextWrapper>
          </div>

          <ButtonWrapper>
            <Button
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan' }}
              size="lg"
              onClick={() => navigate('/login')}
            >
              Explorer Maintenant
            </Button>
          </ButtonWrapper>
        </ContentWrapper>

        {/* Vidéo */}
        <ImageWrapper>
          <video src={searchInstantVideo} autoPlay loop muted playsInline />
        </ImageWrapper>
      </FullWidthBackground>
    </PortailLayout>
  );
}

export default AssistantJuridiquePage;
