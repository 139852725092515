import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Group, Burger, Loader, Select, TextInput, Pagination, Text } from '@mantine/core';
import { NavbarNested } from "../../component/verticalNavbar/NavbarNested";
import { db } from "../../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { IconFileText, IconList, IconSearch, IconBook2, IconBriefcase, IconScale } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

// Styled Components
const PageContainer = styled.div`
  max-width: 900px; /* Limiter la largeur maximale */
  margin: 0 auto; /* Centrer le contenu */
  padding: 1rem;
`;

const FancyListContainer = styled.div`
  margin-top: 1.5rem;
`;

const FancyListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  .title {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Espacement entre l'icône et le texte */
  }

  .icon {
    color: #0056b3;
    font-size: 1.2rem;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-start; /* Aligner la recherche à gauche */
  margin-top: 1rem;

  .search-input {
    max-width: 400px; /* Limiter la largeur */
    width: 100%;
    margin-right: auto; /* Laisser l'espace restant à droite */
  }
`;

const InfoText = styled(Text)`
  margin-top: 1rem;
`;

// Custom component for dropdown items
const SelectItem = ({ label, icon, ...others }) => (
  <div {...others}>
    <Group noWrap>
      {icon}
      <Text>{label}</Text>
    </Group>
  </div>
);

export const LawPage = () => {
  const [docData, setDocData] = useState([]); // Données pour la liste
  const [filteredData, setFilteredData] = useState([]); // Données filtrées pour la recherche
  const [selectedLawType, setSelectedLawType] = useState("code"); // Type de texte sélectionné (valeur par défaut : "code")
  const [searchQuery, setSearchQuery] = useState(""); // Recherche
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle pour la pagination
  const itemsPerPage = 5; // Nombre d'éléments par page
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();

  // Fonction pour charger les données depuis Firebase
  const fetchData = async (lawtype) => {
    try {
      const docRef = doc(db, "doclist", "doclist");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const docTemp = docSnap.data();
        setDocData(docTemp[lawtype] || []); // Charger les données ou une liste vide si aucune donnée
        setFilteredData(docTemp[lawtype] || []); // Initialiser les données filtrées
        setCurrentPage(1); // Réinitialiser la pagination
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
    }
  };

  // Charger les données par défaut au montage du composant
  useEffect(() => {
    if (selectedLawType) {
      fetchData(selectedLawType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Appeler uniquement au montage

  // Mettre à jour le type sélectionné et charger les données associées
  const handleLawTypeChange = (lawType) => {
    setSelectedLawType(lawType); // Mettre à jour le type sélectionné
    setDocData([]); // Réinitialiser les données de la liste
    setFilteredData([]); // Réinitialiser les données filtrées
    fetchData(lawType); // Charger les données pour le type sélectionné
    setSearchQuery(""); // Réinitialiser la recherche
    setCurrentPage(1); // Réinitialiser la page actuelle
  };

  // Filtrer les données en fonction de la recherche
  useEffect(() => {
    if (searchQuery) {
      const filtered = docData.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(1); // Réinitialiser à la première page après une recherche
    } else {
      setFilteredData(docData);
    }
  }, [searchQuery, docData]);

  // Calculer les éléments affichés sur la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredData.slice(startIndex, endIndex);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" w="100%">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <MyNavbar />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar p="md" style={{ backgroundColor: "#F5F5F5" }}>
        <NavbarNested />
      </AppShell.Navbar>

      <AppShell.Main>
        <PageContainer>
          {/* Dropdown pour sélectionner le type */}
          <Select
            label={<span><IconFileText size={18} style={{ marginRight: "8px" }} /> Type de texte</span>}
            placeholder="Sélectionner un type"
            data={[
              { value: 'code', label: 'Codes juridiques', icon: <IconBook2 size={16} /> },
              { value: 'actes-uniformes', label: 'Actes Uniformes', icon: <IconBriefcase size={16} /> },
              { value: 'convention-collective', label: 'Conventions collectives', icon: <IconScale size={16} /> },
              { value: 'convention-fiscale', label: 'Conventions fiscales', icon: <IconBriefcase size={16} /> }
            ]}
            itemComponent={SelectItem}
            value={selectedLawType}
            onChange={handleLawTypeChange}
            size="md"
            clearable
          />

          {/* Recherche */}
          {selectedLawType && (
            <>
              <SearchContainer>
                <TextInput
                  className="search-input"
                  icon={<IconSearch size={16} />}
                  placeholder="Rechercher un texte"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </SearchContainer>

              {/* Informations sur le nombre de textes */}
              <InfoText size="sm" color="dimmed">
                Affichage de {filteredData.length} texte(s) sur un total de {docData.length}.
              </InfoText>
            </>
          )}

          {/* Liste des textes spécifiques */}
          {selectedLawType && (
            <>
              {!docData.length ? (
                <Group mt="md" mb="xs" justify="center">
                  <Loader color="blue" size="xl" type="dots" />
                </Group>
              ) : (
                <>
                  <FancyListContainer>
                    {currentItems.map((codeItem) => (
                      <FancyListItem
                        key={codeItem.doc_id}
                        onClick={() => navigate(`/laws/${selectedLawType}/${codeItem.doc_id}`)}
                      >
                        <span className="title">
                          <IconList size={16} />
                          {codeItem.title}
                        </span>
                        <IconFileText className="icon" />
                      </FancyListItem>
                    ))}
                  </FancyListContainer>

                  {/* Pagination */}
                  <Pagination
                    total={Math.ceil(filteredData.length / itemsPerPage)}
                    value={currentPage}
                    onChange={setCurrentPage}
                    mt="lg"
                  />
                </>
              )}
            </>
          )}
        </PageContainer>
      </AppShell.Main>
    </AppShell>
  );
};
