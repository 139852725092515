import React from "react";
import { Card, Group, Text, ThemeIcon } from "@mantine/core";
import styled from "styled-components";
import { legalInformationData } from "../assets/data/data";
import { Helmet } from "react-helmet";
import image_home from '../assets/image_home.png'; 
import { fonts } from "./themes";
// Wrapper principal pour la section des informations juridiques
const LegalInfoWrapper = styled.div`
  padding: 3rem 1rem;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  box-sizing: border-box;
`;

// Titre principal
const Title = styled.h3`
  font-size: 2.5rem; /* Titre plus grand */
  text-align: center;
  margin-bottom: 3rem;
  font-family: ${fonts.heading};
  color: #38bdf8;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Conteneur des cartes, avec défilement horizontal
const SliderContainer = styled.div`
  display: flex;
  gap: 2rem; /* Espace entre les cartes */
  width: 100%;
  overflow-x: auto; /* Active le défilement horizontal */
  scroll-snap-type: x mandatory; /* Alignement fluide pour le défilement */
  padding: 2rem;
  padding-left: 4rem; /* Ajout d'un padding pour rendre visible la première carte */
  justify-content: flex-start;

  /* Scrollbar par défaut masquée */
  &::-webkit-scrollbar {
    height: 8px; /* Hauteur de la scrollbar */
    background: transparent; /* Masque la scrollbar par défaut */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* Thumb invisible par défaut */
    border-radius: 4px;
    transition: background 0.3s ease; /* Transition pour effet smooth */
  }

  /* Affiche la scrollbar au survol */
  &:hover {
    &::-webkit-scrollbar {
      background: rgba(255, 255, 255, 0.1); /* Couleur de fond */
    }

    &::-webkit-scrollbar-thumb {
      background: #38bdf8; /* Couleur visible au survol */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #2563eb; /* Couleur au survol du thumb */
    }
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05); /* Couleur du track */
    border-radius: 4px;
  }

  /* Compatibilité avec Firefox */
  scrollbar-width: none; /* Scrollbar masquée */
  &:hover {
    scrollbar-width: thin; /* Scrollbar fine au survol */
  }
`;




// Styles pour chaque carte individuelle
const StyledCard = styled(Card)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  font-family: ${fonts.body};
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  flex: 0 0 auto;
  width: 95%;
  max-width: 400px;
  height: auto; /* Ajuste automatiquement la hauteur en fonction du contenu */
  scroll-snap-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  }
`;

// Composant pour une carte individuelle
const CarouselCard = ({ data }) => {
  return (
    <StyledCard shadow="lg" radius="md" padding="lg">
      <Group position="apart" mt="lg">
        <Text weight={700} size="xl" color="#38bdf8">
          {data.title}
        </Text>
        <ThemeIcon
          radius="xl"
          size="xl"
          variant="gradient"
          gradient={{ from: "teal", to: "blue" }}
        >
          {data.icon}
        </ThemeIcon>
      </Group>
      <Text size="md" mt="sm" color="#e2e8f0">
        {data.description}
      </Text>
      {data.message && (
        <Text size="sm" mt="sm" color="#cbd5e1">
          {data.message}
        </Text>
      )}
    </StyledCard>
  );
};

// Composant principal pour la liste d'informations juridiques
export function LegalInformationList() {
  return (
    <>
    <Helmet>
      <title>Plateforme d'intelligence juridique - Spécialisée en Droit sénégalais et africain</title>
      <meta
        name="description"
        content="Découvrez les lois, codes, décisions judiciaires, et conventions collectives du Sénégal ainsi que les textes juridiques de la zone OHADA et CEDEAO. Une plateforme complète pour les besoins juridiques en Afrique."
      />
      <meta
        name="keywords"
        content="droit sénégalais, droit africain, jurisprudence sénégalaise, OHADA, CEDEAO, lois du Sénégal, codes juridiques, décisions de justice, conventions collectives, doctrine juridique en Afrique"
      />
      <meta
        property="og:title"
        content="Plateforme d'intelligence juridique - Spécialisée en Droit sénégalais et africain"
      />
      <meta
        property="og:description"
        content="Consultez les textes juridiques essentiels du Sénégal et d'Afrique : lois, codes, décisions de justice, conventions collectives et doctrine. Accédez à une plateforme intuitive et complète."
      />
      <meta property="og:image" content={image_home} />
      <meta property="og:url" content="https://legi.sn/portail" />
      <meta property="og:type" content="website" />
      <meta
        name="twitter:title"
        content="Plateforme juridique : droit sénégalais et africain"
      />
      <meta
        name="twitter:description"
        content="Accédez facilement aux codes sénégalais, décisions de justice et conventions collectives d'Afrique. Une solution pensée pour vos besoins juridiques."
      />
      <meta name="twitter:image" content={image_home} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>

      <LegalInfoWrapper>
        <Title>
          Un accès simplifié à une diversité d'informations juridiques essentielles
        </Title>
        <SliderContainer>
          {legalInformationData.map((info, index) => (
            <CarouselCard key={index} data={info} />
          ))}
        </SliderContainer>
      </LegalInfoWrapper>
    </>
  );
}

export default LegalInformationList;
