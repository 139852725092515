import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextInput, Textarea, Button, Container, Group, Select, Notification } from '@mantine/core';
import { colors, fonts } from './themes';
import { db } from '../config/firebase-config'; // Import Firebase config
import { collection, addDoc } from 'firebase/firestore';
import { initGA } from '../config/analytics'; // Import your analytics.js file

const ContactUsWrapper = styled.div`
  padding: 4rem 1rem;
  background-color: ${colors.white};
  text-align: center;
`;

const Title = styled.h2`
  font-family: ${fonts.heading};
  color: ${colors.text};
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  font-family: ${fonts.body};
  color: ${colors.text};
  font-size: 1.25rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FormContainer = styled(Container)`
  max-width: 600px;
  margin: 0 auto;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1.5rem;
`;

const StyledTextarea = styled(Textarea)`
  margin-bottom: 1.5rem;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.blue.dark};
  &:hover {
    background-color: ${colors.blue.superDark};
  }
`;

export function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    // Initialise Google Analytics avec l'ID de mesure
    initGA('G-NYGJ94YPV5');

    // Enregistrer un événement de vue de page
    window.gtag('event', 'page_view', {
      page_title: 'Contact Us Page',
      page_path: '/contact',
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !subject || !message) {
      setNotification({ type: 'error', message: 'Veuillez remplir tous les champs.' });
      return;
    }

    try {
      // Ajouter les informations à Firestore
      // eslint-disable-next-line no-unused-vars
      const docRef = await addDoc(collection(db, 'contact'), {
        name,
        email,
        subject,
        message,
        timestamp: new Date(),
      });

      // Enregistrer un événement de soumission de formulaire
      window.gtag('event', 'contact_form_submission', {
        event_category: 'Contact',
        event_label: subject,
        value: 1,
      });

      setNotification({ type: 'success', message: 'Votre message a été envoyé avec succès.' });
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
    } catch (error) {
      setNotification({ type: 'error', message: 'Une erreur est survenue. Veuillez réessayer.' });
      console.error('Error adding document: ', error);
    }
  };

  return (
    <ContactUsWrapper>
      <Title>Contactez-nous</Title>
      <Subtitle>Nous sommes là pour répondre à toutes vos questions et vous aider.</Subtitle>
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <StyledTextInput
            label="Nom complet"
            placeholder="Votre nom complet"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <StyledTextInput
            label="Email"
            placeholder="Votre adresse email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <StyledSelect
            label="Objet"
            placeholder="Sélectionnez un objet"
            data={[
              { value: 'general', label: 'Demande générale' },
              { value: 'support', label: 'Support technique' },
              { value: 'pricing', label: 'Informations sur les tarifs' },
              { value: 'feedback', label: 'Commentaires ou suggestions' },
            ]}
            value={subject}
            onChange={setSubject}
            required
          />
          <StyledTextarea
            label="Message"
            placeholder="Votre message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <Group position="center">
            <StyledButton type="submit">Envoyer</StyledButton>
          </Group>
        </form>
        {notification && (
          <Notification
            color={notification.type === 'success' ? 'green' : 'red'}
            title={notification.type === 'success' ? 'Succès' : 'Erreur'}
            onClose={() => setNotification('')}
            mt="lg"
          >
            {notification.message}
          </Notification>
        )}
      </FormContainer>
    </ContactUsWrapper>
  );
}

export default ContactUs;
