import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Checkbox, Title, Text } from "@mantine/core";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {  db } from "../../src/config/firebase-config";

const FullWidthBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  padding: 2rem;
`;

const PreferencesContainer = styled.div`
  background: #ffffff;
  color: #1e293b;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
`;

const StyledTitle = styled(Title)`
  font-size: 2rem;
  color: #38bdf8;
  margin-bottom: 1rem;
`;

const StyledSubtitle = styled(Text)`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #475569;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const UserPreferences = () => {
  const [preferences, setPreferences] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const availablePreferences = [
    "Droit civil",
    "Droit pénal",
    "Droit commercial",
    "Droit administratif",
    "Droit international",
    "Droit fiscal",
  ];

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));

        if (!authData || !authData.userID) {
          navigate("/login");
          return;
        }

        const userDocRef = doc(db, "users", authData.userID);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().preferences) {
          navigate("/home");
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user preferences:", error);
        setLoading(false);
      }
    };

    fetchPreferences();
  }, [navigate]);

  const handlePreferenceChange = (value) => {
    setPreferences((prev) =>
      prev.includes(value)
        ? prev.filter((pref) => pref !== value)
        : [...prev, value]
    );
  };

  const savePreferences = async () => {
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      if (!authData || !authData.userID) return;

      const userDocRef = doc(db, "users", authData.userID);
      await updateDoc(userDocRef, { preferences });
      navigate("/home");
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };

  if (loading) return <FullWidthBackground>Chargement...</FullWidthBackground>;

  return (
    <FullWidthBackground>
      <PreferencesContainer>
        <StyledTitle>Choisissez vos préférences</StyledTitle>
        <StyledSubtitle>
          Sélectionnez les domaines de droit qui vous intéressent le plus.
        </StyledSubtitle>
        <CheckboxGroup>
          {availablePreferences.map((pref) => (
            <Checkbox
              key={pref}
              label={pref}
              checked={preferences.includes(pref)}
              onChange={() => handlePreferenceChange(pref)}
            />
          ))}
        </CheckboxGroup>
        <Button
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan" }}
          onClick={savePreferences}
        >
          Sauvegarder mes préférences
        </Button>
      </PreferencesContainer>
    </FullWidthBackground>
  );
};

export default UserPreferences;
