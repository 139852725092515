import React from 'react';
import TarifComponent from '../component/TarifComponent';
import PortailLayout from '../component/Layout/PortailLayout';
import TarifExactComponent from '../component/TarifExactComponent'; // Import du nouveau composant

export function TarifsPage() {
  return (
    <PortailLayout>
      {/* Composant des tarifs exacts */}
      <TarifExactComponent />

      {/* Composant existant pour les entreprises */}
      <TarifComponent />
    </PortailLayout>
  );
}

export default TarifsPage;
