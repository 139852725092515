import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Burger, Group, Loader } from '@mantine/core';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { InstantSearch } from 'react-instantsearch';
import { NavbarNested } from '../../component/verticalNavbar/NavbarNested';
import ReactMarkdown from 'react-markdown';
import CustomInfiniteHits from '../../component/articleHits/index';
import queryString from 'query-string';

export const { searchClient } = instantMeiliSearch(
  'https://meilisearch.legi.sn/',
  'MobileDevAppTemp1104',
  {
    primaryKey: 'id',
    keepZeroFacets: true,
    paginationTotalHits: 30,
  }
);

export const Searchai = () => {
  const [opened, { toggle }] = useDisclosure();
  const [healthStatus, setHealthStatus] = useState(null);
  const searchInputRef = useRef('');
  const location = useLocation();

  // Récupérer `searchInput` depuis l'URL
  const { query: searchInput } = queryString.parse(location.search);

  // Récupérer `summary` et `results` depuis `localStorage` en utilisant `searchInput`
  const storedData = localStorage.getItem(`search_summary_${searchInput}`);
  const { summary, results } = storedData ? JSON.parse(storedData) : { summary: '', results: [] };

 // Fonction pour aplatir les résultats et les trier par `law_text` et `titre`
const processResults = (results) => {
    return results
      .map((item) => ({
        ...item.data,         // Déplacer les propriétés sous `data` au niveau supérieur
        title: item.data.titre,
        id: item.id,          // Conserver `id` et `vector_distance` au niveau supérieur
        vector_distance: item.vector_distance,
      }))

  };
  

  // Appliquer la transformation
  const flattenedResults = processResults(results);
  console.log(flattenedResults)
  // Vérifier la santé de MeiliSearch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://meilisearch.legi.sn/health');
        if (!response.ok) throw new Error('Network response was not ok');
        
        const data = await response.json();
        setHealthStatus(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <InstantSearch indexName="law_text" searchClient={searchClient}>
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%" w="100%">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
            <MyNavbar searchInputRef={searchInputRef} />
          </Group>
        </AppShell.Header>

        <AppShell.Navbar p="md" style={{ backgroundColor: '#F5F5F5' }}>
          <NavbarNested />
        </AppShell.Navbar>

        <AppShell.Main>
          {healthStatus ? (
            <>
              {/* Affichage du résumé */}
              <div style={summaryStyle}>
                <h2>Résumé de la recherche</h2>
                <ReactMarkdown>{summary || 'Aucun résumé disponible.'}</ReactMarkdown>
              </div>

              {/* Affichage du contexte des articles avec CustomInfiniteHits */}
              <h3 style={{ marginTop: '20px' }}>Résultats</h3>
              <CustomInfiniteHits hits={flattenedResults} />
            </>
          ) : (
            <Loader color="blue" size="xl" style={{ justifyContent: 'center', marginTop: '20%' }} />
          )}
          <br />
          <br />
        </AppShell.Main>
      </AppShell>
    </InstantSearch>
  );
};

const summaryStyle = {
  padding: '20px',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #ddd',
  marginBottom: '20px',
  textAlign: 'left',
};

export default Searchai;
