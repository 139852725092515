import React, { useState } from 'react';
import styled from 'styled-components';
import CaseCard from './CaseCard';

// Styled Components
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colonnes par défaut */
  gap: 1.5rem; /* Espacement entre les éléments */

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur tablette */
    gap: 1rem; /* Réduit l'espacement */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* 1 colonne sur mobile */
    gap: 0.75rem; /* Réduit encore l'espacement */
  }
`;

const CasesList = ({ cases = [] }) => {
  const [localCases, setLocalCases] = useState(cases); // Gérer l'état localement

  // Fonction pour supprimer un dossier de la liste
  const handleRemoveCase = (caseId) => {
    setLocalCases((prevCases) => prevCases.filter((caseItem) => caseItem.id !== caseId));
  };

  return (
    <GridContainer>
      {localCases.length > 0 ? (
        localCases.map((caseItem) => (
          <CaseCard 
            key={caseItem.id} 
            caseData={caseItem} 
            onRemove={handleRemoveCase} // Passer la fonction au composant enfant
          />
        ))
      ) : (
        <p>Aucun dossier disponible</p> // Message si la liste est vide
      )}
    </GridContainer>
  );
};

export default CasesList;
