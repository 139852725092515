import React from 'react';
import styled from 'styled-components';
import { Card, Text, Button, Grid } from '@mantine/core';

const SectionTitle = styled.h2`
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  color: #0056b3;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SuggestionCard = styled(Card)`
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const Suggestions = () => (
  <>
    <SectionTitle>Suggestions pour vous</SectionTitle>
    <Grid gutter="lg">
      <Grid.Col md={6} sm={12}>
        <SuggestionCard>
          <Text weight={500} size="lg" color="#333">
            Actualités juridiques
          </Text>
          <Text size="sm" color="dimmed" style={{ marginTop: '0.5rem' }}>
            Consultez les dernières actualités en matière de droit sénégalais et africain.
          </Text>
          <Button
            variant="light"
            size="xs"
            style={{ marginTop: '1rem' }}
            onClick={() => window.location.href = '/actualites'}
          >
            Découvrir
          </Button>
        </SuggestionCard>
      </Grid.Col>
      <Grid.Col md={6} sm={12}>
        <SuggestionCard>
          <Text weight={500} size="lg" color="#333">
            Analyse doctrinale
          </Text>
          <Text size="sm" color="dimmed" style={{ marginTop: '0.5rem' }}>
            Explorez les doctrines et analyses juridiques liées à vos domaines d'intérêt.
          </Text>
          <Button
            variant="light"
            size="xs"
            style={{ marginTop: '1rem' }}
            onClick={() => window.location.href = '/doctrines'}
          >
            Explorer
          </Button>
        </SuggestionCard>
      </Grid.Col>
    </Grid>
  </>
);

export default Suggestions;
