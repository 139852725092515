import React, { useState, useMemo } from 'react';
import { Select, Group, Text, Anchor, MultiSelect, Stack, ActionIcon, Tooltip, Loader, Center } from '@mantine/core';
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react';
import FavoriteItem from './FavoriteItem';

const FavoritesList = ({ favorites = [], searchTerm, onUpdate, isLoading = false }) => {
  const [selectedLawTexts, setSelectedLawTexts] = useState([]);
  const [selectedJuridiction, setSelectedJuridiction] = useState('all');
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');

  const favoriteTypes = useMemo(() => {
    return [...new Set(favorites.map(fav => fav.type))];
  }, [favorites]);

  const lawTextOptions = useMemo(() => {
    const lawTextFavorites = favorites.filter(fav => fav.type === 'law_text');
    if (!lawTextFavorites.length) return [];
    
    const options = lawTextFavorites.reduce((acc, fav) => {
      if (fav.law_text && !acc.includes(fav.law_text)) {
        acc.push(fav.law_text);
      }
      return acc;
    }, []);
    
    return options.map(text => ({ value: text, label: text }));
  }, [favorites]);

  const juridictionOptions = useMemo(() => {
    const decisionFavorites = favorites.filter(fav => fav.type === 'décisions');
    if (!decisionFavorites.length) return [];
    
    const options = decisionFavorites.reduce((acc, fav) => {
      if (fav.juridiction && !acc.includes(fav.juridiction)) {
        acc.push(fav.juridiction);
      }
      return acc;
    }, []);
    
    return [{ value: 'all', label: 'Toutes les juridictions' }, ...options.map(juridiction => ({ value: juridiction, label: juridiction }))];
  }, [favorites]);

  const domainOptions = useMemo(() => {
    const decisionFavorites = favorites.filter(fav => fav.type === 'décisions');
    if (!decisionFavorites.length) return [];
    
    const allDomains = new Set();
    decisionFavorites.forEach(fav => {
      if (fav.domaine_decision) {
        fav.domaine_decision.forEach(domain => allDomains.add(domain));
      }
    });
    
    return Array.from(allDomains).map(domain => ({ value: domain, label: domain }));
  }, [favorites]);

  const filteredFavorites = useMemo(() => {
    const filtered = favorites.filter(fav => {
      const matchesSearch = (
        (fav.title?.toLowerCase().includes(searchTerm?.toLowerCase()) || false) ||
        (fav.titre?.toLowerCase().includes(searchTerm?.toLowerCase()) || false) ||
        (fav.text_summary?.toLowerCase().includes(searchTerm?.toLowerCase()) || false) ||
        (fav.corpus?.toLowerCase().includes(searchTerm?.toLowerCase()) || false)
      );

      const matchesLawText = fav.type !== 'law_text' || 
                            selectedLawTexts.length === 0 || 
                            selectedLawTexts.includes(fav.law_text);
      const matchesJuridiction = fav.type !== 'décisions' || selectedJuridiction === 'all' || fav.juridiction === selectedJuridiction;
      const matchesDomains = fav.type !== 'décisions' || selectedDomains.length === 0 || (fav.domaine_decision && fav.domaine_decision.some(domain => selectedDomains.includes(domain)));

      return matchesSearch && matchesLawText && matchesJuridiction && matchesDomains;
    });

    return filtered.sort((a, b) => {
      const dateA = a.timestamp ? new Date(a.timestamp) : new Date(0);
      const dateB = b.timestamp ? new Date(b.timestamp) : new Date(0);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
  }, [favorites, searchTerm, selectedLawTexts, selectedJuridiction, selectedDomains, sortOrder]);

  const renderTitle = (favorite) => {
    const title = favorite.type === 'law_text' ? favorite.titre : favorite.titre;
    const link = favorite.type === 'law_text' ? `/article/${favorite.id}` : `/decision/${favorite.id}`;
    
    return (
      <Anchor href={link} target="_blank" rel="noopener noreferrer">
        <Text weight={500}>{title}</Text>
      </Anchor>
    );
  };

  if (isLoading) {
    return (
      <Center style={{ height: 'calc(100vh - 200px)' }}>
        <Stack align="center" spacing="xs">
          <Loader size="lg" variant="dots" color="blue" />
          <Text size="sm" color="dimmed">Chargement de vos favoris...</Text>
        </Stack>
      </Center>
    );
  }

  if (!favorites.length) {
    return (
      <Center style={{ height: 'calc(100vh - 200px)' }}>
        <Stack align="center" spacing="md">
          <Text size="lg" color="dimmed">Vous n'avez pas encore de favoris</Text>
          <Text size="sm" color="dimmed">Ajoutez des articles ou des décisions à vos favoris pour les retrouver ici</Text>
        </Stack>
      </Center>
    );
  }

  return (
    <Stack spacing="md">
      <Stack spacing="xs">
        <br />
        <Group position="apart">
          <Text size="lg" weight={600}>Mes Favoris</Text>
          <Group spacing="xs" align="center">
            <Text size="sm" color="dimmed">Trier par date</Text>
            <Tooltip label={sortOrder === 'desc' ? "Plus ancien d'abord" : "Plus récent d'abord"}>
              <ActionIcon
                variant="light"
                color="blue"
                onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}
                size="md"
              >
                {sortOrder === 'desc' ? <IconArrowDown size={16} /> : <IconArrowUp size={16} />}
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>
  
        {(favoriteTypes.includes('law_text') || favoriteTypes.includes('décisions')) && (
          <Group grow spacing="md">
            {favoriteTypes.includes('law_text') && (
              <MultiSelect
                label="Textes de loi"
                placeholder="Filtrer par textes"
                data={lawTextOptions}
                value={selectedLawTexts}
                onChange={setSelectedLawTexts}
                searchable
                clearable
                size="sm"
              />
            )}
            
            {favoriteTypes.includes('décisions') && (
              <>
                <Select
                  label="Juridiction"
                  placeholder="Filtrer par juridiction"
                  data={juridictionOptions}
                  value={selectedJuridiction}
                  onChange={setSelectedJuridiction}
                  searchable
                  clearable
                  size="sm"
                />
                <MultiSelect
                  label="Domaines juridiques"
                  placeholder="Filtrer par domaine"
                  data={domainOptions}
                  value={selectedDomains}
                  onChange={setSelectedDomains}
                  searchable
                  clearable
                  size="sm"
                />
              </>
            )}
          </Group>
        )}
      </Stack>
  
      <Stack spacing="sm">
        {filteredFavorites.length === 0 ? (
          <Center style={{ padding: '2rem' }}>
            <Text color="dimmed">Aucun résultat ne correspond à vos critères de recherche</Text>
          </Center>
        ) : (
          filteredFavorites.map((fav, index) => (
            <FavoriteItem 
              key={fav.docId || index} 
              favorite={fav} 
              searchTerm={searchTerm}
              onUpdate={onUpdate}
              renderTitle={() => renderTitle(fav)}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};

export default FavoritesList;
