import React, { useState } from 'react';
import { Card, Text, Badge, Button, UnstyledButton, Container, Modal } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import { IconGavel, IconBook } from '@tabler/icons-react';

import he from 'he'; // Utilisé pour décoder les entités HTML
import styled from 'styled-components';
import FavoriteButton from '../favorite/favoriteButton';
import AddToCaseButton from '../addToCase/addToCaseButton';
const accentColor = '#3498db';
const accentLightColor = '#85c1e9';
const textColor = '#333';

const StyledCard = styled(Card)`
  background: linear-gradient(135deg, #fff, #f9f9f9);
  border-radius: 12px;
  width: 400px;
  margin: 1.5em;
  padding: 1em;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5em;
  border-bottom: 1px solid ${accentColor};
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const CategoryBadge = styled(Badge)`
  background-color: ${accentColor};
  color: white;
  padding: 0.3em 0.6em;
  border-radius: 8px;
  font-size: 0.7em;
`;

const CategoryIcon = styled.div`
  background-color: ${accentLightColor};
  border-radius: 50%;
  padding: 0.4em;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: 1em;
  right: 1em;
`;

const StyledText = styled.span`
  font-size: 0.85em;
  font-weight: 600;
  color: ${textColor};
  cursor: pointer; /* Indique que l'élément est cliquable */
  transition: text-decoration 0.3s ease; /* Animation fluide pour le soulignement */

  &:hover {
    text-decoration: underline; /* Ajoute un soulignement au survol */
  }
`;


const StyledLawText = styled(Text)`
  font-size: 0.85em;
  font-style: italic;
  color: ${textColor};
  margin-top: 0.5em;
`;





const StyledSpoilerText = styled.div`
  font-size: 0.95em;
  padding: 1em 0;
  text-align: left;
  color: ${textColor};
  line-height: 1.6;
  max-height: ${props => (props.expanded ? 'none' : '16em')};
  overflow: hidden;
`;

const BadgeContainer = styled.div`
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
`;

const DecisonsCard = (props) => {
  const navigate = useNavigate();
  const [modalOpened, setModalOpened] = useState(false);
  const categories = ["Lois et Réglements", "Conventions fiscales", "Conventions collectives","Directives","Accords","Traités","Divers"];
  const {
    category_text,
    title = 'Aucun titre',
    id,
    law_text = '', // Ajout du champ law_text par défaut
    text_summary = 'Aucun résumé disponible',
    corpus = 'Pas de corpus disponible',
    keyword_decision = [],
    domaine_decision = [],
    theme_decision = [],
  } = props;

  const categoryIcon = category_text === "Décisions" ? <IconGavel size={16} /> : <IconBook size={16} />;
  const navigateToArticle = (type, userId) => {
    navigate( categories.includes(type) ? `/article/${userId}` : `/decision/${userId}`);
  };
  const safeDecode = (value) => (value ? he.decode(value) : value);


  const highlightedTitle = safeDecode(props._highlightResult?.title?.value) || safeDecode(props._highlightResult?.titre?.value)  || title;
  const highlightedLawText = safeDecode(props._highlightResult?.law_text?.value) || law_text;
  const highlightedTextSummary = safeDecode(props._highlightResult?.text_summary?.value) || text_summary;
  const highlightedCorpus = safeDecode(props._highlightResult?.corpus?.value) || corpus;
  return (
    <>
      <StyledCard shadow="sm" radius="md">
        <Header>
          <TitleSection>
            <CategoryIcon>{categoryIcon}</CategoryIcon>
            <CategoryBadge>{category_text}</CategoryBadge>
          </TitleSection>
          <IconsContainer >
          <AddToCaseButton hit={props} typetext={category_text} />

          <FavoriteButton hit={props} typetext={category_text} />
          </IconsContainer>
        </Header>

        <UnstyledButton onClick={() => navigateToArticle(category_text, id)} style={{ width: '100%', textAlign: 'left' }}>
          <StyledText>
            <span dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
          </StyledText>
        </UnstyledButton>
        
        {/* Affichage de law_text sous le titre */}
        <StyledLawText>
          <div dangerouslySetInnerHTML={{ __html: highlightedLawText }} />
        </StyledLawText>

        <StyledSpoilerText>
          <div dangerouslySetInnerHTML={{ __html:  categories.includes(category_text)  ? highlightedCorpus : highlightedTextSummary }} />
        </StyledSpoilerText>

        <Button variant="subtle" color="blue" onClick={() => setModalOpened(true)} size="xs">
          Voir plus
        </Button>
      </StyledCard>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title={ <span dangerouslySetInnerHTML={{ __html: highlightedTitle }} />}
        size="lg"
      >
        <Text>
          <div dangerouslySetInnerHTML={{ __html: categories.includes(category_text)? highlightedCorpus : highlightedTextSummary }} />
        </Text>

        <BadgeContainer>
          {domaine_decision.length > 0 && (
            <div>
              <Text weight={500} size="sm">Domaines:</Text>
              {domaine_decision.map((domaine, index) => (
                <Badge key={index} color="blue" variant="light">{domaine}</Badge>
              ))}
            </div>
          )}

          {theme_decision.length > 0 && (
            <div>
              <Text weight={500} size="sm">Thèmes:</Text>
              {theme_decision.map((theme, index) => (
                <Badge key={index} color="green" variant="light">{theme}</Badge>
              ))}
            </div>
          )}

          {keyword_decision.length > 0 && (
            <div>
              <Text weight={500} size="sm">Mots Clés:</Text>
              {keyword_decision.map((keyword, index) => (
                <Badge key={index} color="yellow" variant="light">{keyword}</Badge>
              ))}
            </div>
          )}
        </BadgeContainer>
      </Modal>
    </>
  );
};

export function ArticleHit(props) {
  return (
    <Container style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <DecisonsCard {...props.hit} />
    </Container>
  );
}
