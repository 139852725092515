import React, { useState, useEffect } from 'react';
import {
  Container,
  Title,
  Group,
  Text,
  Loader,
  Center,
  TextInput,
  ActionIcon,
  Modal,
  Stack,
  Button,
  Textarea,
} from '@mantine/core';
import { IconSortAscending2, IconSortDescending2, IconFolderPlus } from '@tabler/icons-react';
import { collection, getDocs, query, orderBy, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import Layout from '../../component/Layout/Layout';
import CasesList from './components/CasesList';
import { notifications } from '@mantine/notifications';

const Dossiers = () => {
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]); // Liste filtrée
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // État pour la recherche
  const [sortOrder, setSortOrder] = useState('desc'); // État pour le tri (par défaut décroissant)
  const [isModalOpen, setIsModalOpen] = useState(false); // État pour le modal
  const [newCaseName, setNewCaseName] = useState(''); // Nom du nouveau dossier
  const [newCaseDescription, setNewCaseDescription] = useState(''); // Description du nouveau dossier
  const { userID } = useGetUserInfo();

  // Fonction pour récupérer les dossiers depuis Firestore
  const fetchCases = async () => {
    if (!userID) return;

    try {
      setIsLoading(true); // Activer le chargement
      const casesRef = collection(db, 'case_folders', userID, 'folders');
      const q = query(casesRef, orderBy('created_at', sortOrder)); // Tri basé sur sortOrder
      const snapshot = await getDocs(q);

      const casesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setCases(casesData);
      setFilteredCases(casesData); // Initialiser la liste filtrée
    } catch (error) {
      console.error('Error fetching cases:', error);
    } finally {
      setIsLoading(false); // Désactiver le chargement
    }
  };

  // Charger les dossiers au montage du composant ou lorsque sortOrder change
  useEffect(() => {
    fetchCases();// eslint-disable-next-line
  }, [userID, sortOrder]);

  // Filtrer les dossiers en fonction de la recherche (name et description)
  useEffect(() => {
    const filtered = cases.filter((caseItem) => {
      const nameMatch =
        caseItem.name && caseItem.name?.toLowerCase().includes(searchQuery?.toLowerCase());
      const descriptionMatch =
        caseItem.description &&
        caseItem.description.toLowerCase().includes(searchQuery?.toLowerCase());

      return nameMatch || descriptionMatch; // Inclure si l'un des deux correspond
    });

    setFilteredCases(filtered);
  }, [searchQuery, cases]);

  // Basculer l'ordre de tri entre ascendant et descendant
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
  };

  // Vérifier si un dossier existe déjà avant de le créer
  const createNewCase = async () => {
    if (!newCaseName.trim() || !userID) return;

    try {
      const caseRef = doc(db, 'case_folders', userID, 'folders', newCaseName);
      const existingCasesSnapshot = await getDocs(collection(db, 'case_folders', userID, 'folders'));
      const caseExists = existingCasesSnapshot.docs.some((doc) => doc.id === newCaseName);

      if (caseExists) {
        notifications.show({
          title: 'Erreur',
          message: `Le dossier "${newCaseName}" existe déjà.`,
          color: 'red',
          autoClose: 3000,
        });
        return;
      }

      await setDoc(caseRef, {
        created_at: serverTimestamp(),
        name: newCaseName.trim(),
        description: newCaseDescription.trim(),
      });

      notifications.show({
        title: 'Succès',
        message: `Le dossier "${newCaseName}" a été créé avec succès.`,
        color: 'green',
        autoClose: 3000,
      });

      resetModal();

      // Rafraîchir la liste des dossiers après ajout
      fetchCases();
    } catch (error) {
      console.error('Erreur lors de la création du dossier :', error);
      notifications.show({
        title: 'Erreur',
        message: "Une erreur s'est produite lors de la création du dossier.",
        color: 'red',
        autoClose: 3000,
      });
    }
  };

  // Réinitialiser le modal après création ou fermeture
  const resetModal = () => {
    setIsModalOpen(false);
    setNewCaseName('');
    setNewCaseDescription('');
  };

  if (isLoading) {
    return (
      <Layout>
        <Center style={{ height: 'calc(100vh - 200px)' }}>
          <Loader size="xl" />
        </Center>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container size="xl" py="xl">
        {/* En-tête */}
        <Group position="apart" mb="xl">
          <div>
            <Title order={1}>Mes Dossiers</Title>
            <Text color="dimmed">Gérez vos dossiers et documents juridiques</Text>
          </div>

          {/* Barre de recherche */}
          <TextInput
            placeholder="Rechercher par nom ou description"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ maxWidth: '300px' }}
          />
        </Group>

        {/* Icône de tri */}
        <Group position="right" mb="md">
          {/* Icône pour créer un nouveau dossier */}
          <ActionIcon variant="filled" color="blue" onClick={() => setIsModalOpen(true)} title="Créer un nouveau dossier">
            <IconFolderPlus size={24} />
          </ActionIcon>
          <ActionIcon variant="light" onClick={toggleSortOrder} title="Changer l'ordre de tri">
            {sortOrder === 'desc' ? (
              <IconSortDescending2 size={24} />
            ) : (
              <IconSortAscending2 size={24} />
            )}
          </ActionIcon>
          <Text>Trié par date ({sortOrder === 'desc' ? 'Plus récent' : 'Plus ancien'})</Text>
        </Group>

        {/* Liste des dossiers */}
        {filteredCases.length > 0 ? (
          <CasesList cases={filteredCases} />
        ) : (
          <Text color="dimmed" align="center">
            Aucun dossier trouvé correspondant à votre recherche.
          </Text>
        )}

        {/* Modal pour créer un nouveau dossier */}
        <Modal opened={isModalOpen} onClose={resetModal} title="Créer un nouveau dossier">
          <Stack spacing="md">
            <TextInput
              label="Nom du dossier"
              placeholder="Entrez le nom du dossier"
              value={newCaseName}
              onChange={(e) => setNewCaseName(e.target.value)}
              required
            />
            <Textarea
              label="Description"
              placeholder="Décrivez le contenu du dossier"
              value={newCaseDescription}
              onChange={(e) => setNewCaseDescription(e.target.value)}
              autosize
              minRows={2}
              maxRows={4}
            />
            <Button fullWidth onClick={createNewCase}>
              Créer le dossier
            </Button>
          </Stack>
        </Modal>
      </Container>
    </Layout>
  );
};

export default Dossiers;
