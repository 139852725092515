import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Paper, Title, Text, Loader, Group, Button, Drawer } from '@mantine/core';
import { showNotification } from '@mantine/notifications'; // Importer showNotification
import { IconCheck, IconX, IconArrowLeft, IconMenu2 } from '@tabler/icons-react'; // Icônes pour les notifications et le bouton flottant
import { collection, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase-config';
import { useGetUserInfo } from '../../../hooks/useGetUserInfo';
import Layout from '../../../component/Layout/Layout';
import DocumentsList from './DocumentsList';
import DocumentView from './DocumentView';
import styled from 'styled-components';

// Styled Components
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row; /* Aligne les éléments côte à côte sur desktop */
    gap: 1rem; /* Ajoute un espacement entre les colonnes */
  }
`;

const SidebarDocumentsList = styled.div`
  display: none; /* Masque la liste des documents par défaut */

  @media (min-width: 768px) {
    display: block; /* Affiche la liste uniquement sur desktop */
    flex: 1; /* Occupe une partie proportionnelle de l'espace */
    max-width: 400px; /* Largeur maximale de la liste */
    background-color: #f8f9fa;
    overflow-y: auto;
  }
`;

const FloatingButton = styled(Button)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  background-color: #007bff;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #0056b3;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    display: none; /* Cache le bouton sur desktop */
  }
`;

const ContentArea = styled.div`
  flex: 2; /* La zone principale occupe plus d'espace */
`;

const CaseView = () => {
  const { caseId } = useParams();
  const navigate = useNavigate();
  const { userID } = useGetUserInfo();

  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [caseInfo, setCaseInfo] = useState(null);
  
  // État pour gérer l'ouverture du Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Récupérer les données du dossier et des documents
  useEffect(() => {
    const fetchData = async () => {
      if (!userID || !caseId) return;

      try {
        // Récupérer les informations du dossier
        const caseRef = doc(db, 'case_folders', userID, 'folders', caseId);
        const caseSnap = await getDoc(caseRef);
        if (caseSnap.exists()) {
          setCaseInfo({ id: caseSnap.id, ...caseSnap.data() });
        }

        // Récupérer les documents
        const docsRef = collection(db, 'case_folders', userID, 'folders', caseId, 'documents');
        const snapshot = await getDocs(docsRef);
        const docsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDocuments(docsData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userID, caseId]);

  // Fonction pour supprimer un document
  const handleDeleteDocument = async (docId) => {
    try {
      // Supprimer le document de Firestore
      await deleteDoc(doc(db, 'case_folders', userID, 'folders', caseId, 'documents', docId));

      // Mettre à jour localement la liste des documents
      setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== docId));

      // Afficher une notification de succès
      showNotification({
        title: 'Succès',
        message: 'Le document a été supprimé avec succès',
        color: 'green',
        icon: <IconCheck size={16} />,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression du document :', error);

      // Afficher une notification d'erreur
      showNotification({
        title: 'Erreur',
        message: "Une erreur s'est produite lors de la suppression du document",
        color: 'red',
        icon: <IconX size={16} />,
      });
    }
  };

  if (isLoading) {
    return (
      <Layout>
        <Container style={{ height: 'calc(100vh - 200px)' }} display="flex" align="center" justify="center">
          <Loader size="xl" />
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container size="xl" py="md">
        {/* En-tête */}
        <Group position="apart" mb="xl">
          <Group>
            <Button
              variant="subtle"
              leftIcon={<IconArrowLeft size={16} />}
              onClick={() => navigate('/dossiers')}
            >
              Retour aux dossiers
            </Button>
            <div>
              <Title order={2}>{caseInfo?.name}</Title>
              <Text color="dimmed" size="sm">
                Créé le {new Date(caseInfo?.created_at?.seconds * 1000).toLocaleDateString()}
              </Text>
            </div>
          </Group>
        </Group>

        {/* Bouton flottant pour afficher la liste sur mobile */}
        <FloatingButton onClick={() => setIsDrawerOpen(true)}>
          <IconMenu2 size={24} />
        </FloatingButton>

        {/* Drawer pour afficher la liste des documents sur mobile */}
        <Drawer
          opened={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          title="Liste des documents"
          padding="md"
          size="md"
        >
          <DocumentsList 
            documents={documents} 
            onSelectDocument={(doc) => {
              setSelectedDoc(doc);
              setIsDrawerOpen(false); // Fermer le Drawer après sélection
            }}
            selectedDocId={selectedDoc?.id}
            onDelete={handleDeleteDocument} // Passe la fonction `handleDeleteDocument`
          />
        </Drawer>

        {/* Conteneur principal */}
        <FlexContainer>
          {/* Liste des documents visible uniquement sur desktop */}
          <SidebarDocumentsList>
            <DocumentsList 
              documents={documents} 
              onSelectDocument={setSelectedDoc}
              selectedDocId={selectedDoc?.id}
              onDelete={handleDeleteDocument}
            />
          </SidebarDocumentsList>

          {/* Zone principale */}
          <ContentArea>
            {selectedDoc ? (
              <DocumentView document={selectedDoc} />
            ) : (
              <Paper p="xl" withBorder style={{ textAlign: 'center' }}>
                <Text color="dimmed">Sélectionnez un document pour le visualiser</Text>
              </Paper>
            )}
          </ContentArea>
        </FlexContainer>
      </Container>
    </Layout>
  );
};

export default CaseView;
