import React, { useState, useEffect } from 'react';
import { Text, Group, Badge, Collapse, Box, Tabs, Button } from '@mantine/core';
import { IconGavel, IconFileText, IconFileAnalytics, IconScale, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import ReactMarkdown from 'react-markdown';

const FavoriteItemDecision = ({ favorite, searchTerm, expanded, renderTitle }) => {
  const [activeTab, setActiveTab] = useState('summary');
  const [showFullDecision, setShowFullDecision] = useState(false);
  const [truncatedDecision, setTruncatedDecision] = useState('');
  const [fullDecision, setFullDecision] = useState('');

  useEffect(() => {
    if (favorite.decisions_brut) {
      // Diviser le HTML en lignes tout en préservant les balises
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = favorite.decisions_brut;
      const textContent = tempDiv.textContent || tempDiv.innerText;
      const lines = textContent.split('\n');
      
      // Créer une version tronquée
      const truncated = lines.slice(0, 25).join('\n');
      tempDiv.textContent = truncated;
      setTruncatedDecision(favorite.decisions_brut.slice(0, favorite.decisions_brut.indexOf(lines[25]) || favorite.decisions_brut.length));
      setFullDecision(favorite.decisions_brut);
    }
  }, [favorite.decisions_brut]);

  return (
    <>
      <Group position="apart" style={{ marginBottom: '0.5rem' }}>
        <Group>
          <IconGavel size={16} />
          {renderTitle()}
        </Group>
        <Badge color="green">Décision</Badge>
      </Group>

      {favorite.domaine_decision && favorite.domaine_decision.length > 0 && (
        <Group spacing="xs" style={{ marginTop: '0.5rem' }} position="left">
          {favorite.domaine_decision.map((domain, index) => (
            <Badge 
              key={index} 
              size="sm" 
              variant="outline" 
              color="blue"
            >
              {domain}
            </Badge>
          ))}
        </Group>
      )}
      
      <Collapse in={expanded}>
        <Box mt="md">
          <Tabs value={activeTab} onChange={setActiveTab} variant="outline" size="sm">
            <Tabs.List>
              <Tabs.Tab 
                value="summary" 
                icon={<IconFileText size={14} />}
              >
                Résumé
              </Tabs.Tab>
              <Tabs.Tab 
                value="analysis" 
                icon={<IconFileAnalytics size={14} />}
              >
                Analyse
              </Tabs.Tab>
              <Tabs.Tab 
                value="full" 
                icon={<IconScale size={14} />}
              >
                Décision
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="summary" pt="xs">
              <Box style={{ textAlign: 'left' }}>
                <ReactMarkdown
                  className="markdown-content"
                  components={{
                    p: ({ children }) => (
                      <Text align="left" size="sm">
                        {children}
                      </Text>
                    )
                  }}
                >
                  {favorite.text_summary}
                </ReactMarkdown>
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="analysis" pt="xs">
              <Box style={{ textAlign: 'left' }}>
                <ReactMarkdown
                  className="markdown-content"
                  components={{
                    p: ({ children }) => (
                      <Text align="left" size="sm">
                        {children}
                      </Text>
                    )
                  }}
                >
                  {favorite.corpus}
                </ReactMarkdown>
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="full" pt="xs">
              <Box style={{ textAlign: 'left' }}>
                <div
                  dangerouslySetInnerHTML={{ 
                    __html: showFullDecision ? fullDecision : truncatedDecision 
                  }}
                />
                {truncatedDecision !== fullDecision && (
                  <Button
                    variant="subtle"
                    size="sm"
                    onClick={() => setShowFullDecision(!showFullDecision)}
                    leftIcon={showFullDecision ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
                    style={{ marginTop: '1rem' }}
                  >
                    {showFullDecision ? 'Voir moins' : 'Voir plus'}
                  </Button>
                )}
              </Box>
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Collapse>
    </>
  );
};

export default FavoriteItemDecision;
