import React from 'react';
import styled from 'styled-components';
import { Accordion, Container, Title } from '@mantine/core';
import image from '../assets/faq.svg';
import { colors } from './themes';

// Données des questions-réponses
const faqData = [
  {
    value: 'reset-password',
    question: 'Comment réinitialiser mon mot de passe ?',
    answer: (
      <>
        Pour réinitialiser votre mot de passe, rendez-vous sur la page de connexion en cliquant sur le   {' '}
        <a href="/login" style={{ color: colors.blue.main, textDecoration: 'underline' }}>
        lien
        </a>
        . Cliquez ensuite sur "Mot de passe oublié" et suivez les instructions envoyées par e-mail.
      </>
    ),
  },
  {
    value: 'multiple-accounts',
    question: 'Puis-je créer plusieurs comptes ?',
    answer:
      'Oui, vous pouvez créer plusieurs comptes, mais chaque compte nécessite une adresse e-mail unique. Il est autorisé de s\'enregistrer avec Google ou Facebook pour un compte et de créer un autre compte manuel avec un mot de passe distinct.',
  },
  {
    value: 'newsletter',
    question: 'Comment puis-je m\'abonner à la newsletter mensuelle ?',
    answer: (
      <>
        Vous pouvez vous abonner à la newsletter dans les paramètres de votre compte, une fois inscrit. Cliquez sur ce lien pour{' '}
        <a href="/register" style={{ color: colors.blue.main, textDecoration: 'underline' }}>
          vous inscrire
        </a>{' '}
        et accéder aux options de gestion de la newsletter.
      </>
    ),
  },
  {
    value: 'data-security',
    question: 'Comment mes données juridiques sont-elles sécurisées ?',
    answer:
      'Vos données sont stockées de manière sécurisée avec des protocoles de cryptage de pointe pour garantir leur confidentialité et leur intégrité.',
  },
  {
    value: 'subscription-costs',
    question: 'Quels sont les coûts d\'abonnement ?',
    answer:
      'Nos tarifs sont flexibles et adaptés à vos besoins spécifiques, avec des options pour les petites entreprises, les cabinets juridiques, et les grandes entreprises.',
  },
];

// Styled components
const FaqWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: ${colors.blue.superDark};
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), 0 12px 25px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
`;

const StyledContainer = styled(Container)`
  padding: 2rem;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #e0e0e0;

  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 1.5rem;
  color: #2d3748;
  font-family: 'Greycliff CF', sans-serif;
`;

export function FaqWithImage() {
  return (
    <FaqWrapper>
      <StyledContainer>
        <ImageWrapper>
          <StyledImage src={image} alt="Frequently Asked Questions" />
        </ImageWrapper>
        <ContentWrapper>
          <StyledTitle order={2}>Questions fréquentes</StyledTitle>

          <Accordion chevronPosition="right" variant="separated">
            {faqData.map((item) => (
              <Accordion.Item key={item.value} value={item.value}>
                <Accordion.Control>{item.question}</Accordion.Control>
                <Accordion.Panel>{item.answer}</Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </ContentWrapper>
      </StyledContainer>
    </FaqWrapper>
  );
}

export default FaqWithImage;
