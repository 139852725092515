import React from 'react';
import styled from 'styled-components';
import Layout from '../../component/Layout/Layout';
import RecentArticles from './RecentArticles';
import QuickActions from './QuickActions';
import Suggestions from './Suggestions';
import { Text, Card } from '@mantine/core';

const FullWidthContainer = styled.div`
  padding: 2rem;
  background: #f9f9f9;
  width: 100%;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const PageTitle = styled.h1`
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: #003366;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    text-align: center;
  }
`;

const SectionTitle = styled.h2`
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  color: #0056b3;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const WelcomeCard = styled(Card)`
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
`;

export function Home() {
  return (
    <Layout>
      <FullWidthContainer>
        <PageTitle>Bienvenue sur votre espace personnel</PageTitle>
        <QuickActions />
        {/* Message déplacé ici sous Actions rapides */}
        <WelcomeCard>
          <Text size="lg" weight={600} color="#333">
            Gérez vos recherches juridiques en un seul endroit
          </Text>
          <Text size="sm" color="dimmed" style={{ marginTop: '0.5rem' }}>
            Accédez rapidement à vos informations juridiques, suivez vos recherches récentes, et explorez les nouvelles fonctionnalités de la plateforme.
          </Text>
        </WelcomeCard>
        <Suggestions />
        <SectionTitle>Articles récemment consultés</SectionTitle>
        <RecentArticles />
      </FullWidthContainer>
    </Layout>
  );
}

export default Home;
