import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Title, Text } from "@mantine/core";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../config/firebase-config";
import { useNavigate } from "react-router-dom";
import PortailLayout from "../component/Layout/PortailLayout";

const FullWidthBackground = styled.div`
  background: linear-gradient(to bottom, #f8fafc, #e2e8f0);
  color: #1e293b;
  padding: 3rem 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const VerificationContainer = styled.div`
  text-align: center;
  padding: 3rem;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
`;

const StyledTitle = styled(Title)`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #1e293b;
`;

const StyledSubtitle = styled(Text)`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #475569;
`;

const SuccessMessage = styled(Text)`
  color: green;
  margin-top: 1rem;
`;

const ErrorMessage = styled(Text)`
  color: red;
  margin-top: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const EmailVerificationPage = () => {
  const [verificationSent, setVerificationSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countdown, setCountdown] = useState(30); // Initialisation du compteur à 30 secondes
  const [showResendButton, setShowResendButton] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setShowResendButton(true);
    }
  }, [countdown]);

  const handleSendVerificationEmail = async () => {
    setErrorMessage("");
    try {
      await sendEmailVerification(auth.currentUser);
      setVerificationSent(true);
      setShowResendButton(false);
      setCountdown(30); // Réinitialise le compteur
    } catch (error) {
      setErrorMessage("Erreur lors de l'envoi de l'e-mail de vérification.");
    }
  };

  const handleCheckVerification = async () => {
    await auth.currentUser.reload();
    if (auth.currentUser.emailVerified) {
      navigate("/home"); // Redirige vers la page d'accueil ou un tableau de bord
    } else {
      setErrorMessage("Votre email n'est pas encore vérifié. Veuillez réessayer.");
    }
  };

  return (
    <PortailLayout>
      <FullWidthBackground>
        <VerificationContainer>
          <StyledTitle>Vérifiez votre e-mail</StyledTitle>
          <StyledSubtitle>
            Nous avons envoyé un e-mail de vérification à votre adresse. Veuillez vérifier votre boîte de réception et cliquer sur le lien de validation.
          </StyledSubtitle>
          <ButtonWrapper>
            {!verificationSent && (
              <Button
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan" }}
                onClick={handleSendVerificationEmail}
              >
                Renvoyer l'e-mail de vérification
              </Button>
            )}
            {verificationSent && (
              <SuccessMessage>
                Un nouvel e-mail de vérification a été envoyé. Veuillez vérifier votre boîte de réception.
              </SuccessMessage>
            )}
            {showResendButton ? (
              <Button
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan" }}
                onClick={handleSendVerificationEmail}
              >
                Renvoyer l'e-mail
              </Button>
            ) : (
              <Text>Vous pouvez renvoyer l'e-mail dans {countdown} secondes.</Text>
            )}
            <Button
              variant="outline"
              onClick={handleCheckVerification}
            >
              Vérifier à nouveau
            </Button>
          </ButtonWrapper>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </VerificationContainer>
      </FullWidthBackground>
    </PortailLayout>
  );
};

export default EmailVerificationPage;
