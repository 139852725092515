import React, { useState, useEffect } from 'react';
import { ActionIcon, Tooltip, Modal, Stack, TextInput, Textarea, Select, Button, Group, Text } from '@mantine/core';
import { IconFolderPlus, IconPlus } from '@tabler/icons-react';
import { doc, setDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from "../../config/firebase-config";
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { motion } from 'framer-motion';
import { notifications } from '@mantine/notifications';

const AddToCaseButton = ({ hit, typetext }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cases, setCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [newCaseName, setNewCaseName] = useState('');
  const [newCaseDescription, setNewCaseDescription] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userID } = useGetUserInfo();
  const categories = ["Lois et Réglements", "Conventions fiscales", "Conventions collectives","Directives","Accords","Traités","Divers"];

  const type = categories.includes(typetext)  ? "law_text" : typetext?.toLowerCase();

  const showSuccessNotification = (caseName) => {
    notifications.show({
      title: 'Ajout réussi',
      message: `Document ajouté au dossier "${caseName}"`,
      color: 'green',
      autoClose: 3000,
    });
  };

  const showErrorNotification = (error) => {
    notifications.show({
      title: 'Erreur',
      message: 'Une erreur est survenue lors de l\'ajout au dossier',
      color: 'red',
      autoClose: 3000,
    });
  };

  const getDocumentId = () => {
    return type === 'Lois et Réglements' ? hit.id_collection : hit.id;
  };

  const fetchCases = async () => {
    if (!userID) return;
    
    const casesRef = collection(db, 'case_folders', userID, 'folders');
    const casesSnapshot = await getDocs(casesRef);
    const casesData = casesSnapshot.docs.map(doc => ({
      value: doc.id,
      label: doc.id,
    }));
    setCases(casesData);
  };

const createNewCase = async () => {
  if (!newCaseName.trim() || !userID) return;

  try {
    // Vérifier si le dossier existe déjà
    const caseRef = doc(db, 'case_folders', userID, 'folders', newCaseName);
    const caseSnapshot = await getDocs(collection(db, 'case_folders', userID, 'folders'));
    const caseExists = caseSnapshot.docs.some((doc) => doc.id === newCaseName);

    if (caseExists) {
      notifications.show({
        title: "Erreur",
        message: `Le dossier "${newCaseName}" existe déjà.`,
        color: "red",
        autoClose: 3000,
      });
      return; // Arrêter ici si le dossier existe
    }

    // Créer le nouveau dossier
    await setDoc(caseRef, {
      created_at: serverTimestamp(),
      name: newCaseName,
      description: newCaseDescription.trim(),
      theme: '',
      docs_order: [],
      doc_info: true,
    });

    await addToCase(newCaseName); // Ajouter le document au nouveau dossier
    showSuccessNotification(newCaseName); // Notification de succès
    resetModal(); // Réinitialiser le modal après création
  } catch (error) {
    console.error("Erreur lors de la création du dossier :", error);
    notifications.show({
      title: "Erreur",
      message: "Une erreur est survenue lors de la création du dossier.",
      color: "red",
      autoClose: 3000,
    });
  }
};

  const addToCase = async (caseName) => {
    if (!caseName || !userID) return;

    const docId = getDocumentId();
    const docRef = doc(db, 'case_folders', userID, 'folders', caseName, 'documents', docId);

    const documentData = {
      id: docId,
      type: type,
      title: hit.titre || hit.title,
      comments: '',
      timestamp: serverTimestamp(),
    };

    await setDoc(docRef, documentData);
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchCases();
    }// eslint-disable-next-line
  }, [isModalOpen, userID]);

  const handleAddToCase = async () => {
    setIsLoading(true);
    try {
      if (isCreatingNew) {
        await createNewCase();
      } else if (selectedCase) {
        await addToCase(selectedCase);
        showSuccessNotification(selectedCase);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error('Error adding to case:', error);
      showErrorNotification(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setIsCreatingNew(false);
    setNewCaseName('');
    setNewCaseDescription('');
    setSelectedCase(null);
  };

  return (
    <>
      <Tooltip label="Ajouter au dossier">
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          style={{ display: 'inline-flex' }}
        >
          <ActionIcon
            color="blue"
            onClick={() => setIsModalOpen(true)}
            variant="subtle"
            size="lg"
            radius="xl"
          >
            <IconFolderPlus size={20} />
          </ActionIcon>
        </motion.div>
      </Tooltip>

      <Modal
        opened={isModalOpen}
        onClose={resetModal}
        title={
          <Text size="lg" weight={500}>
            {isCreatingNew ? "Créer un nouveau dossier" : "Ajouter au dossier"}
          </Text>
        }
        size="md"
      >
        <Stack spacing="md">
          {isCreatingNew ? (
            <>
              <TextInput
                label="Nom du dossier"
                placeholder="Entrez le nom du dossier"
                value={newCaseName}
                onChange={(event) => setNewCaseName(event.currentTarget.value)}
                required
              />
              <Textarea
                label="Description"
                placeholder="Décrivez le contenu de ce dossier"
                value={newCaseDescription}
                onChange={(event) => setNewCaseDescription(event.currentTarget.value)}
                autosize
                minRows={2}
                maxRows={4}
              />
            </>
          ) : (
            <Select
              label="Sélectionner un dossier"
              placeholder="Choisir un dossier existant"
              data={cases}
              value={selectedCase}
              onChange={setSelectedCase}
              searchable
              clearable
              nothingFound="Aucun dossier trouvé"
              required
            />
          )}

          <Group position="apart">
            <Button
              variant="subtle"
              onClick={() => {
                setIsCreatingNew(!isCreatingNew);
                setNewCaseDescription('');
              }}
              leftIcon={<IconPlus size={16} />}
            >
              {isCreatingNew ? "Sélectionner un dossier existant" : "Créer un nouveau dossier"}
            </Button>

            <Button
              onClick={handleAddToCase}
              loading={isLoading}
              disabled={(!isCreatingNew && !selectedCase) || (isCreatingNew && !newCaseName.trim())}
            >
              {isLoading ? "Ajout en cours..." : "Ajouter"}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default AddToCaseButton;
