import React from 'react';
import styled from 'styled-components';
import { Text, ActionIcon } from '@mantine/core';
import { IconBook, IconGavel, IconFile, IconTrash } from '@tabler/icons-react';

// Styled Components
const Sidebar = styled.div`
  width: auto;
  max-width: 400px; /* Largeur maximale pour la liste */
  margin-right: 1rem; /* Espacement avec le contenu principal */
  overflow-y: auto; /* Permet le défilement si nécessaire */
  background-color: #ffffff;
  padding: 0.5rem;

  @media (max-width: 768px) {
    max-width: none; /* Utilise toute la largeur sur mobile */
    margin-right: unset; /* Supprime l'espacement sur mobile */
    padding: 0.5rem; /* Ajuste les marges sur mobile */
    border-right: none; /* Supprime la bordure sur mobile */
    box-shadow: none; /* Supprime l'ombre sur mobile */
    overflow-y: visible; /* Pas de défilement spécifique sur mobile */
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const DocumentItem = styled.div`
  display: flex;
  flex-direction: column; /* Aligne les éléments verticalement */
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: ${({ isSelected }) => (isSelected ? '#e7f5ff' : 'transparent')};
  border: ${({ isSelected }) => (isSelected ? '2px solid #4dabf7' : '1px solid #dee2e6')};
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: #f1f3f5;
    transform: scale(1.02);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between; /* Aligne les éléments aux extrémités */
  width: 100%; /* Prend toute la largeur disponible */
`;

const DateText = styled(Text)`
  font-size: 0.75rem; /* Réduit la taille de la date */
`;

const DocumentText = styled.div`
  flex-grow: 1; /* Permet au texte d'occuper l'espace disponible */
  
  & > span:first-child {
    font-weight: bold;
    font-size: 0.85rem; /* Réduit la taille du titre */
    color: #343a40;
    white-space: normal; /* Permet au texte d'aller à la ligne */
    word-wrap: break-word; /* Coupe les mots longs si nécessaire */
    overflow-wrap: break-word; /* Compatibilité supplémentaire */
    overflow: hidden; /* Empêche le débordement visuel */
    text-overflow: ellipsis; /* Ajoute "..." si nécessaire */
    line-height: 1.4; /* Améliore l'espacement des lignes */
    max-height: calc(1.4em * 3); /* Limite à trois lignes visibles */
    display: -webkit-box; /* Pour le support des lignes multiples */
    -webkit-line-clamp: 3; /* Limite à trois lignes */
    -webkit-box-orient: vertical; /* Nécessaire pour -webkit-line-clamp */
    margin-top: .5rem
`;

const DocumentsList = ({ documents, onSelectDocument, selectedDocId, onDelete }) => {
   const getIcon = (type) => {
     switch (type) {
       case 'law_text':
         return <IconBook size={24} />;
       case 'décisions':
         return <IconGavel size={24} />;
       default:
         return <IconFile size={24} />;
     }
   };

   return (
     <Sidebar>
       <Wrapper>
         {documents.length === 0 ? (
           <Text color="dimmed" align="center">
             Aucun document dans ce dossier
           </Text>
         ) : (
           documents.map((doc) => (
             <DocumentItem
               key={doc.id}
               isSelected={selectedDocId === doc.id}
               onClick={() => onSelectDocument(doc)}
             >
               {/* Ligne supérieure avec la date et l'icône supprimer */}
               <HeaderRow>
                 <DateText color="dimmed">
                   {new Date(doc.timestamp?.seconds * 1000).toLocaleDateString()}
                 </DateText>
                 <ActionIcon
                   color="red"
                   variant="light"
                   onClick={(e) => {
                     e.stopPropagation(); // Empêche le clic d'afficher le document
                     onDelete(doc.id);
                   }}
                 >
                   <IconTrash size={16} />
                 </ActionIcon>
               </HeaderRow>

               {/* Icône et titre */}
               <div style={{ display:"flex",alignItems:"center"}}>
                {getIcon(doc.type)}
                  <DocumentText>
                 <span>{doc.title}</span>
                </DocumentText>
                </div>
             </DocumentItem>
           ))
         )}
       </Wrapper>
     </Sidebar>
   );
};

export default DocumentsList;
