import React, { useState } from "react";
import styled from "styled-components";
import { Button, Title, Text } from "@mantine/core";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { useNavigate } from "react-router-dom";
import { IconBrandGoogle, IconBrandFacebook, IconAt, IconLock } from "@tabler/icons-react";
import imageLogin from "../../assets/image_home.png";
import PortailLayout from "../../component/Layout/PortailLayout";
import { PasswordReset } from "../ResetPassword";

const FullWidthBackground = styled.div`
  background: linear-gradient(to bottom, #f8fafc, #e2e8f0);
  color: #1e293b;
  padding: 3rem 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${imageLogin});
    background-size: cover;
    background-position: center;
    opacity: 0.15;
    z-index: 1;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1));
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1));
  }
`;

const LoginWrapper = styled.div`
  z-index: 2;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  max-width: 600px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #1e293b;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #475569;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 1.5rem;

  input {
    width: 100%;
    padding: 0.75rem 2.5rem;
    border-radius: 8px;
    border: 1px solid #d1d5db;
    background: #f8fafc;
    color: #1e293b;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: #3b82f6;
      background: #ffffff;
      box-shadow: 0 0 8px rgba(59, 130, 246, 0.2);
    }
  }

  .input-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
    transition: transform 0.3s ease, color 0.3s ease;

    input:focus ~ & {
      transform: translateY(-50%) scale(1.2);
      color: #3b82f6;
    }
  }
`;

const SocialButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: 500;
  background: ${({ color }) => color || "#ffffff"};
  color: ${({ textColor }) => textColor || "#1e293b"};
  border: 1px solid #d1d5db;

  &:hover {
    background: ${({ hoverColor }) => hoverColor || "#f3f4f6"};
    color: ${({ hoverTextColor }) => hoverTextColor || "#1e293b"};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export function Login({ signInWithGoogle, signInWithFacebook }) {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginpwd, setLoginpwd] = useState("");
  const [msgLoginError, setMsgLoginError] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, loginEmail, loginpwd)
      .then((user) => {
        const authInfo = {
          userID: user.user.uid,
          name: null,
          profilePhoto: null,
          isAuth: true,
          emailVerified: user.user.emailVerified,
        };
        localStorage.setItem("auth", JSON.stringify(authInfo));
        if (auth.currentUser && !auth.currentUser.emailVerified) {
          navigate("/email-verification");
          console.log("not verified")
        }else {
          console.log("verified")
          navigate("/home");

        }
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            setMsgLoginError("Email invalide");
            break;
          case "auth/user-not-found":
            setMsgLoginError("Email non trouvé");
            break;
          case "auth/wrong-password":
            setMsgLoginError("Mot de passe incorrect");
            break;
          default:
            setMsgLoginError(error.message);
        }
        setTimeout(() => setMsgLoginError(""), 3000);
      });
  };

  return (
    <PortailLayout>
      <FullWidthBackground>
        {showResetPassword ? (
          <PasswordReset onBackToLogin={() => setShowResetPassword(false)} />
        ) : (
          <LoginWrapper>
            <StyledTitle>Bienvenue !</StyledTitle>
            <StyledSubtitle>Connectez-vous pour accéder à vos outils juridiques.</StyledSubtitle>
            {msgLoginError && <Text color="red">{msgLoginError}</Text>}

            <InputWrapper>
              <input
                placeholder="Adresse email"
                type="email"
                value={loginEmail}
                onChange={(event) => setLoginEmail(event.target.value)}
              />
              <IconAt className="input-icon" />
            </InputWrapper>

            <InputWrapper>
              <input
                placeholder="Mot de passe"
                type="password"
                value={loginpwd}
                onChange={(event) => setLoginpwd(event.target.value)}
              />
              <IconLock className="input-icon" />
            </InputWrapper>

            <Button
              fullWidth
              variant="filled"
              style={{ background: "#3b82f6", color: "#ffffff", marginBottom: "1rem" }}
              onClick={handleSignIn}
            >
              Se connecter
            </Button>

            <StyledSubtitle>Ou connectez-vous avec :</StyledSubtitle>
            <SocialButton
              fullWidth
              onClick={signInWithGoogle}
              color="#ffffff"
              hoverColor="#f3f4f6"
              hoverTextColor="#3b82f6"
            >
              <IconBrandGoogle />
              Google
            </SocialButton>
            <SocialButton
              fullWidth
              onClick={signInWithFacebook}
              color="#ffffff"
              hoverColor="#f3f4f6"
              hoverTextColor="#4b4cfc"
            >
              <IconBrandFacebook />
              Facebook
            </SocialButton>

            <Button
              variant="link"
              fullWidth
              style={{ marginTop: "1rem", color: "#3b82f6",   background: "none"}}
              onClick={() => setShowResetPassword(true)}
            >
              Mot de passe oublié ?
            </Button>
          </LoginWrapper>
        )}
      </FullWidthBackground>
    </PortailLayout>
  );
}

export default Login;
