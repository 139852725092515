// src/pages/Favoris/Favoris.jsx
import React, { useState, useEffect } from 'react';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import Layout from '../../component/Layout/Layout';
import { fetchFavorites } from './favoriteService';
import SearchBar from './components/SearchBar';
import TabNavigation from './components/TabNavigation';
import FavoritesList from './components/FavoritesList';
import { Stack } from '@mantine/core';

const Favoris = () => {
  const [favorites, setFavorites] = useState({ 'law_text': [], 'décisions': [] });
  const [activeTab, setActiveTab] = useState('law_text');
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { userID } = useGetUserInfo();

  useEffect(() => {
    if (userID) {
      loadFavorites();
    }// eslint-disable-next-line
  }, [userID]);

  const loadFavorites = async () => {
    setIsLoading(true);
    try {
      const fetchedFavorites = await fetchFavorites(userID);
      setFavorites(fetchedFavorites);
    } catch (error) {
      console.error('Erreur lors du chargement des favoris:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Layout>
      <Stack spacing="md" style={{ padding: '2rem' }}>
        <SearchBar onSearch={handleSearch} />
        <TabNavigation 
          activeTab={activeTab} 
          onTabChange={handleTabChange}
          lawTextCount={favorites['law_text'].length}
          decisionsCount={favorites['décisions'].length}
        />
        <FavoritesList 
          favorites={favorites[activeTab]} 
          searchTerm={searchTerm}
          onUpdate={loadFavorites}
          isLoading={isLoading}
        />
      </Stack>
    </Layout>
  );
};

export default Favoris;
