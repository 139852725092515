import React, { useState, useEffect } from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconHeartFilled, IconHeart } from '@tabler/icons-react';
import { doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from "../../config/firebase-config";
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import { motion } from 'framer-motion';

const FavoriteButton = ({ hit, typetext }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const { userID } = useGetUserInfo();
  const categories = ["Lois et Réglements", "Conventions fiscales", "Conventions collectives","Directives","Accords","Traités","Divers"];

  const type = categories.includes(typetext)  ? "law_text" : typetext?.toLowerCase();

  const getDocumentId = () => {
    return type === 'Lois et Réglements' ? hit.id_collection : hit.id;
  };

  const checkFavorite = async () => {
    if (!userID) return;
    const docId = getDocumentId();
    const docRef = doc(db, 'favorites', userID, type, docId);
    const docSnap = await getDoc(docRef);
    setIsFavorite(docSnap.exists());
  };

  const toggleFavorite = async () => {
    if (!userID) {
      console.error('User not logged in.');
      return;
    }

    const docId = getDocumentId();
    const docRef = doc(db, 'favorites', userID, type, docId);

    if (isFavorite) {
      await deleteDoc(docRef);
    } else {
      const favoriteData = {
        id: docId,
        comments: '',
        timestamp: serverTimestamp(),
      };
      await setDoc(docRef, favoriteData);
    }
    setIsFavorite(!isFavorite);
  };

  useEffect(() => {
    checkFavorite();// eslint-disable-next-line
  }, [hit.id, hit.id_collection, userID, type]);

  return (
    <Tooltip label={isFavorite ? 'Retirer des favoris' : 'Ajouter aux favoris'}>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        style={{ display: 'inline-flex' }}
      >
        <ActionIcon
          color={isFavorite ? 'red' : 'gray'}
          onClick={toggleFavorite}
          variant={isFavorite ? 'light' : 'subtle'}
          size="lg"
          radius="xl"
          style={{
            transition: 'all 0.3s ease',
          }}
        >
          {isFavorite ? (
            <IconHeartFilled
              size={20}
              style={{
                transition: 'transform 0.3s ease',
              }}
            />
          ) : (
            <IconHeart
              size={20}
              style={{
                transition: 'transform 0.3s ease',
              }}
            />
          )}
        </ActionIcon>
      </motion.div>
    </Tooltip>
  );
};

export default FavoriteButton;
