import React from 'react';
import { Card, Text, Group, Tabs, Container } from '@mantine/core';
import { db } from '../../config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import styled from "styled-components";
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import RelatedArticles from '../RelatedArticles';
import CustomInfiniteHits from "../articleHits/index";

import './articlePage.css';
const MainArticleContainer = styled(Container)`
  margin-top: 2em;
`;

const MainArticleCard = styled(Card)`
  background: linear-gradient(135deg, #e3f2fd, #ffffff);
  border: 2px solid #3498db;
  border-radius: 16px;
  padding: 2em;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const ArticleTitle = styled(Text)`
  font-weight: 700;
  font-size: 1.8rem;
  text-align: center;
  color: #003366;
`;

const ArticleContent = styled.div`
  margin-top: 1.5em;
  padding: 1.5em;
  background: #ffffff;
  border-radius: 8px;
  text-align: justify;
  line-height: 1.8;
  color: #333;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;
const StyledClickableText = styled.span`
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5em;
  cursor: pointer;
  text-decoration: underline;
  color: #555;
  display: inline-block;
  transition: color 0.2s ease-in-out, transform 0.1s;

  &:hover {
    color: #003366;
    transform: scale(1.05); /* Légère mise en valeur au survol */
  }

  &:active {
    color: #001f4d; /* Couleur plus foncée lorsqu'il est cliqué */
    transform: scale(0.95); /* Légère réduction pour un effet de clic */
  }
`;

const LawTextLink = ({ lawText, collectionId, idSuffix }) => {
  const handleClick = () => {
    window.location.href = `/laws/${collectionId}/${idSuffix}`;
  };

  return (
    <StyledClickableText onClick={handleClick}>
      {lawText}
    </StyledClickableText>
  );
};

const Anchorpage = ({ beforelink, afterlink }) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '1em' }}>
      <Group justify="center" gap="md">
        {beforelink && (
          <a
            href={`/article/${beforelink}`}
            style={{
              textDecoration: 'none',
              color: '#555',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5em',
              transition: 'color 0.2s ease-in-out',
            }}
            className="nav-link"
            onMouseOver={(e) => (e.currentTarget.style.color = '#003366')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#555')}
          >
            <IconArrowLeft size={18} /> Article précédent
          </a>
        )}
        {afterlink && (
          <a
            href={`/article/${afterlink}`}
            style={{
              textDecoration: 'none',
              color: '#555',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5em',
              transition: 'color 0.2s ease-in-out',
            }}
            className="nav-link"
            onMouseOver={(e) => (e.currentTarget.style.color = '#003366')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#555')}
          >
            Article suivant <IconArrowRight size={18} />
          </a>
        )}
      </Group>
    </div>
  );
};
const RelatedArticlesRecursive = ({ currentId, limit = 10 }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchArticle = async (id, depth, direction, articles = []) => {
    if (!id || depth >= limit) return articles;

    try {
      const docRef = doc(db, "law_text", id);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        const filteredData = { ...data };
        delete filteredData.embeddings;
        filteredData.title = filteredData.titre
        // Ajouter uniquement si l'article n'existe pas encore
        if (!articles.some((article) => article.id === id)) {
          articles.push({ id, ...filteredData,
          });
        }

        const nextId = direction === "before" ? data.before : data.after;
        return fetchArticle(nextId, depth + 1, direction, articles);
      }
    } catch (error) {
      console.error(`Erreur lors de la récupération des données pour ${id}:`, error);
    }
    return articles;
  };

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      setLoading(true);
      const beforeArticles = await fetchArticle(currentId, 0, "before");
      const afterArticles = await fetchArticle(currentId, 0, "after");
      const allArticles = [...beforeArticles.reverse(), ...afterArticles];

      // Supprimez les doublons si jamais ils persistent
      const uniqueArticles = allArticles.filter(
        (article, index, self) =>
          index === self.findIndex((a) => a.id === article.id)
      );

      setResults(uniqueArticles);
      setLoading(false);
    };
    fetchRelatedArticles();// eslint-disable-next-line
  }, [currentId]);

  if (loading) {
    return <Loader color="blue" size="md" />;
  }

  if (results.length === 0) {
    return <Text size="sm" color="dimmed">Aucun article en relation disponible.</Text>;
  }

  return <CustomInfiniteHits hits={results} />;
};

const ArticleCard = ({ userId, onDataLoad }) => {
  const [articleData, setArticleData] = useState(null);

  // Enregistrer un article récemment consulté dans localStorage
  const saveRecentArticle = (article) => {
    const recentArticles = JSON.parse(localStorage.getItem("recentArticles")) || [];
    const updatedArticles = [
      {
        id: article.id,
        titre: article.titre,
        law_text: article.law_text,
        date: new Date().toISOString(),
      },
      ...recentArticles.filter((a) => a.id !== article.id), // Éviter les doublons
    ].slice(0, 10); // Limiter à 10 articles

    localStorage.setItem("recentArticles", JSON.stringify(updatedArticles));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "law_text", userId);
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
          const data = snapshot.data();
          setArticleData(data);

          // Enregistrer l'article dans localStorage
          saveRecentArticle({ ...data, id: userId });

          if (onDataLoad) {
            onDataLoad(data);
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
      }
    };

    fetchData();
  }, [userId, onDataLoad]);

  if (!articleData) {
    return (
      <Container size="sm" style={{ marginTop: "2em", textAlign: "center" }}>
        <Loader color="blue" size="xl" type="dots" />
      </Container>
    );
  }

  return (
    <MainArticleContainer>
      <Anchorpage beforelink={articleData.before} afterlink={articleData.after} />
      <hr></hr>
      <MainArticleCard>
        {/* Titre de l'article */}
        <ArticleTitle>{articleData.titre}</ArticleTitle>

        {/* Texte cliquable */}
        <LawTextLink
          lawText={articleData.law_text}
          collectionId={articleData.law_text_collectionId}
          idSuffix={articleData.id_suffix}
        />

        {/* Contenu de l'article */}
        <ArticleContent
          dangerouslySetInnerHTML={{ __html: articleData.corpus }}
        />
      </MainArticleCard>
      <hr></hr>
      {/* Articles liés */}
      <RelatedArticlesRecursive currentId={userId} />
    </MainArticleContainer>
  );
};




const ArticleContentPage = (props) => {

  const [articleData, setArticleData] = useState(null);
  const handleDataLoad = (data) => {
    setArticleData(data); // Mettez à jour articleData lorsque les données sont chargées
  };
  let orient = null;
  if (window.innerWidth <= 1000) {
    orient = 'horizontal';
  } else {
    orient = 'vertical';
  }

  return (
    <div>
      <Tabs defaultValue="article" orientation={orient} >
        <Tabs.List>
          <Tabs.Tab value="article">Article</Tabs.Tab>
          <Tabs.Tab value="decision">Décisions</Tabs.Tab>
          <Tabs.Tab value="comment">Commentaires</Tabs.Tab>
          <Tabs.Tab value="law">Articles cités ou en lien</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="article">
          {/* Passer handleDataLoad pour recevoir articleData */}
          <ArticleCard {...props} onDataLoad={handleDataLoad} />
        </Tabs.Panel>
        <Tabs.Panel value="decision">
        <Text size="sm" color="dimmed" style={{ textAlign: 'center', marginTop: '20px' }}>
              Aucune décisions disponible pour cet article.
            </Text>

        </Tabs.Panel>
        <Tabs.Panel value="comment">
            <Text size="sm" color="dimmed" style={{ textAlign: 'center', marginTop: '20px' }}>
              Aucun commentaire disponible pour cet article.
            </Text>
        </Tabs.Panel>
        <Tabs.Panel value="law">
          {/* Passer `corpus` au composant RelatedArticles */}
          {articleData?.corpus ? (
            <RelatedArticles corpus={articleData.corpus} title={articleData.titre} law_text={articleData.law_text}/>
          ) : (
            <Text size="sm" color="dimmed" style={{ textAlign: 'center', marginTop: '20px' }}>
              Aucun contenu disponible pour cet article.
            </Text>
          )}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default ArticleContentPage;