// src/pages/Favoris/components/SearchBar.jsx
import React from 'react';
import { TextInput } from '@mantine/core';

const SearchBar = ({ onSearch }) => {
  return (
    <TextInput
      placeholder="Rechercher dans vos favoris..."
      onChange={(event) => onSearch(event.currentTarget.value)}
      style={{ marginBottom: '1rem' }}
    />
  );
};

export default SearchBar;
