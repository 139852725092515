import React, { useState, useEffect } from 'react';
import { Paper, Loader } from '@mantine/core';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase-config';
import ItemDecision from './ItemDecision';
import ItemArticle from './ItemArticle';

const DocumentView = ({ document }) => {
  const [fullDocument, setFullDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFullDocument = async () => {
      if (!document) return;
      
      try {
        setIsLoading(true);
        const collectionName = document.type === 'law_text' ? 'law_text' : 'decisions';
        const docRef = doc(db, collectionName, document.id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          const { embedding, ...documentWithoutEmbedding } = data;
          setFullDocument({
            ...document,
            ...documentWithoutEmbedding
          });
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFullDocument();
  }, [document]);

  if (!document) return null;
  if (isLoading) return <Paper p="md" withBorder><Loader /></Paper>;

  return (
    <Paper p="md" withBorder>
      {document.type === 'law_text' ? (
        <ItemArticle document={fullDocument} />
      ) : document.type === 'décisions' ? (
        <ItemDecision document={fullDocument} />
      ) : (
        <div>Type de document non pris en charge</div>
      )}
    </Paper>
  );
};

export default DocumentView;
