import React, { useState } from 'react';
import {
  IconHome2,
  IconBooks,
  IconBook,
  IconSearch,
  IconDoorEnter,
  IconFiles,IconArticle,IconBook2,IconFolder,
  IconHeart
} from '@tabler/icons-react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ScrollableNavbar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10px 0;
  background-color: #f5f5f5;
`;

const StyledNavLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 50px;
  margin: 8px 0;
  cursor: pointer;
  color: ${({ active }) => (active ? '#0056b3' : '#555')};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #0056b3;
  }

  ${({ 'data-label': label }) =>
    label &&
    `
    &:hover::after {
      content: attr(data-label);
      position: absolute;
      top: 50%;
      left: 60px;
      transform: translateY(-50%);
      white-space: nowrap;
      background-color: #333;
      color: #fff;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 0.75rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  `}
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#0056b3' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#555')};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ active }) => (active ? '#003d99' : '#e9ecef')};
    color: ${({ active }) => (active ? '#fff' : '#0056b3')};
  }
`;

const SubItemsContainer = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  transition: opacity 0.3s ease-in-out;
`;

const SubItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#e9ecef' : 'transparent')};
  color: ${({ active }) => (active ? '#0056b3' : '#555')};
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover {
    background-color: #e9ecef;
    color: #0056b3;
  }

  ${({ 'data-label': label }) =>
    label &&
    `
    &:hover::after {
      content: attr(data-label);
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      white-space: nowrap;
      background-color: #333;
      color: #fff;
      padding: 5px 10px;
      border-radius: 4px;
      font-size: 0.75rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  `}
`;

const PortalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-top: auto;
  border: none;
  border-radius: 50%;
  background-color: #0056b3;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #003d99;
  }

  &:hover::after {
    content: 'Portail';
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    white-space: nowrap;
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.75rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export function NavbarNested() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeParent, setActiveParent] = useState(null);

  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <ScrollableNavbar>
      <StyledNavLink
        active={isActive('/home')}
        onClick={() => navigate('/home')}
        data-label="Accueil"
      >
        <IconWrapper active={isActive('/home')}>
          <IconHome2 size="1.5rem" />
        </IconWrapper>
      </StyledNavLink>

      <StyledNavLink
        active={isActive('/search')}
        onClick={() => navigate('/search')}
        data-label="Recherche"
      >
        <IconWrapper active={isActive('/search')}>
          <IconSearch size="1.5rem" />
        </IconWrapper>
      </StyledNavLink>

      <StyledNavLink
        active={isActive('/law')}
        onClick={() => navigate('/law')}
        data-label="Consulter les textes"
      >
        <IconWrapper active={isActive('/law')}>
          <IconBooks size="1.5rem" />
        </IconWrapper>
      </StyledNavLink>




      {/* Doctrines */}
      <StyledNavLink>
        <IconWrapper
          active={activeParent === 'doctrines'}
          onClick={() =>
            setActiveParent((prev) =>
              prev === 'doctrines' ? null : 'doctrines'
            )
          }
          data-label="Doctrines"
        >
          <IconBook size="1.5rem" />
        </IconWrapper>
        <SubItemsContainer visible={activeParent === 'doctrines'}>
          <SubItem
            active={isActive('/doctrines/ouvrages')}
            onClick={() => navigate('/doctrines/ouvrages')}
            data-label="Ouvrages"
          >
            <IconBook2 size="1rem" />
          </SubItem>
          <SubItem
            active={isActive('/doctrines/articles')}
            onClick={() => navigate('/doctrines/articles')}
            data-label="Articles"
          >
            <IconArticle size="1rem" />
          </SubItem>
          <SubItem
            active={isActive('/doctrines/melanges')}
            onClick={() => navigate('/doctrines/melanges')}
            data-label="Mélanges"
          >
            <IconFiles size="1rem" />
          </SubItem>
        </SubItemsContainer>
      </StyledNavLink>
      <StyledNavLink
        active={isActive('/dossiers')}
        onClick={() => navigate('/dossiers')}
        data-label="Mes dossiers"
      >
        <IconWrapper active={isActive('/dossiers')}>
          <IconFolder size="1.5rem" />
        </IconWrapper>
      </StyledNavLink>
      <StyledNavLink
        active={isActive('/favoris')}
        onClick={() => navigate('/favoris')}
        data-label="Mes favoris"
      >
        <IconWrapper active={isActive('/favoris')}>
          <IconHeart size="1.5rem" />
        </IconWrapper>
      </StyledNavLink>
      <PortalButton onClick={() => navigate('/portail')}>
        <IconDoorEnter size="1.5rem" />
      </PortalButton>
    </ScrollableNavbar>
  );
}
