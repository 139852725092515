import React from 'react'
import { useParams } from 'react-router-dom';
import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell,Group,Burger } from '@mantine/core';
import DecisionContentPage from '../../component/decisionPageContent/'
import {NavbarNested} from "../../component/verticalNavbar/NavbarNested"

export const DecisionPage = (props) => {
    let { userId } = useParams();
    const [opened, { toggle }] = useDisclosure();
    const articleData = {
        userId
      };
    
     return (
            <AppShell
              header={{ height: 60 }}
              navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
              padding="md"
            >
              <AppShell.Header>
              <Group h="100%" w="100%">
                <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                <MyNavbar />
                </Group>
              </AppShell.Header>
              <AppShell.Navbar p="md" style={{"backgroundColor":"#F5F5F5"}} >
              <NavbarNested />
              </AppShell.Navbar>
      
              <AppShell.Main>
                <DecisionContentPage {...articleData}/>
                 
              
              </AppShell.Main>
            </AppShell>
          );
          
    
}
