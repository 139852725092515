// src/pages/Favoris/favoriteService.js
import { collection, getDocs, updateDoc, deleteDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase-config';

export const fetchFavorites = async (userID) => {
  const types = ['law_text', 'décisions'];
  const newFavorites = {};

  for (const type of types) {
    const favoritesRef = collection(db, 'favorites', userID, type);
    const favoritesSnapshot = await getDocs(favoritesRef);
    const favoritesList = [];

    for (const favoriteDoc of favoritesSnapshot.docs) {
      const favoriteData = favoriteDoc.data();
      const contentRef = doc(db, type === 'law_text' ? 'law_text' : 'decisions', favoriteData.id);
      const contentSnapshot = await getDoc(contentRef);
      
      if (contentSnapshot.exists()) {
        const content = contentSnapshot.data();
        const { embedding, ...contentWithoutEmbedding } = content;
        
        // Vérification et conversion du timestamp
        const timestamp = favoriteData.timestamp ? 
          new Date(favoriteData.timestamp.seconds * 1000) : 
          null;

        favoritesList.push({
          ...favoriteData,
          ...contentWithoutEmbedding,
          docId: favoriteDoc.id,
          type,
          timestamp
        });
      }
    }

    newFavorites[type] = favoritesList;
  }

  return newFavorites;
};


export const updateFavoriteComment = async (userID, type, docId, comment) => {
  const favoriteRef = doc(db, 'favorites', userID, type, docId);
  await updateDoc(favoriteRef, { 
    comments: comment,
    timestamp: serverTimestamp() // Mise à jour du timestamp lors de la modification
  });
};

export const deleteFavorite = async (userID, type, docId) => {
  const favoriteRef = doc(db, 'favorites', userID, type, docId);
  await deleteDoc(favoriteRef);
};
