import React from 'react';
import styled from 'styled-components';
import { Card, Text, Container, ActionIcon, Group } from '@mantine/core';
import { IconClock, IconBook } from '@tabler/icons-react';

const RecentArticlesWrapper = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center; /* Aligne les cartes au centre */
`;

const StyledCard = styled(Card)`
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 1rem;
  width: calc(25% - 1rem); /* 4 colonnes */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1200px) {
    width: calc(33.33% - 1rem); /* 3 colonnes */
  }

  @media (max-width: 900px) {
    width: calc(50% - 1rem); /* 2 colonnes */
  }

  @media (max-width: 600px) {
    width: calc(100% - 1rem); /* 1 colonne */
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  color: #2d3748;
  font-size: 1.5rem;
  text-align: center;
`;

const ArticleTitle = styled(Text)`
  font-weight: bold;
  color: #003366;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #0056b3;
  }
`;

const ArticleLawText = styled(Text)`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #555;
`;

const ArticleFooter = styled(Group)`
  margin-top: 0.5rem;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const IconWrapper = styled(ActionIcon)`
  background-color: #f1f5f9;
  color: #003366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #e2e8f0;
  }
`;

const RecentArticles = () => {
  const recentArticles = JSON.parse(localStorage.getItem('recentArticles')) || [];

  if (recentArticles.length === 0) {
    return (
      <Container>
        <Text size="sm" color="dimmed" align="center">
          Aucun article récemment consulté.
        </Text>
      </Container>
    );
  }

  return (
    <RecentArticlesWrapper>
      <Title>Articles récemment consultés</Title>
      <CardsContainer>
        {recentArticles.map((article) => (
          <StyledCard
            key={article.id}
            onClick={() => {
              window.location.href = `/article/${article.id}`;
            }}
          >
            <Group align="center" mb="sm">
              <IconWrapper size="lg">
                <IconBook size={20} />
              </IconWrapper>
              <ArticleTitle>
                {article.titre}
              </ArticleTitle>
            </Group>
            <ArticleLawText>{article.law_text}</ArticleLawText>
            <ArticleFooter>
              <Group spacing="xs">
                <IconClock size={16} />
                <Text size="xs" color="gray">
                  Consulté le : {new Date(article.date).toLocaleString()}
                </Text>
              </Group>
            </ArticleFooter>
          </StyledCard>
        ))}
      </CardsContainer>
    </RecentArticlesWrapper>
  );
};

export default RecentArticles;
