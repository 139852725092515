import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Burger, Group } from '@mantine/core';
import { TableOfContentsFloating } from '../../component/search/TableContent';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { InstantSearch } from 'react-instantsearch';
import { Loader } from '@mantine/core';
import React, { useState, useEffect, useRef } from 'react';
import 'instantsearch.css/themes/satellite.css';
import { NavbarNested } from '../../component/verticalNavbar/NavbarNested';
import SearchSummary from '../../component/SearchSummary';

export const { searchClient } = instantMeiliSearch(
  'https://meilisearch.legi.sn/',
  'MobileDevAppTemp1104',
  {
    primaryKey: 'id',
    keepZeroFacets: true,
    paginationTotalHits: 30,
  }
);

export const Search = () => {
  const [opened, { toggle }] = useDisclosure();
  const [healthStatus, setHealthStatus] = useState(null);
  const searchInputRef = useRef('');
  const [currentSearchInput, setCurrentSearchInput] = useState(''); // État pour la valeur actuelle

  // Vérifier la santé de MeiliSearch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://meilisearch.legi.sn/health');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setHealthStatus(data);
        if (data) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchData, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Écoute les changements dans searchInputRef.current
  useEffect(() => {
    const interval = setInterval(() => {
      if (searchInputRef.current !== currentSearchInput) {
        setCurrentSearchInput(searchInputRef.current); // Met à jour currentSearchInput
      }
    }, 500); // Vérifie toutes les 500ms

    return () => clearInterval(interval);
  }, [currentSearchInput]);

  return (
    <InstantSearch indexName="law_text" searchClient={searchClient}>
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: 100, breakpoint: 'sm', collapsed: { mobile: !opened } }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%" w="100%">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
            <MyNavbar searchInputRef={searchInputRef} />
          </Group>
        </AppShell.Header>

        <AppShell.Navbar p="md" style={{ backgroundColor: '#F5F5F5' }}>
          <NavbarNested />
        </AppShell.Navbar>

        <AppShell.Main>
          {healthStatus ? (
            <>
              {/* Afficher SearchSummary uniquement si currentSearchInput est non nul et de taille supérieure à 0 */}
              {currentSearchInput && currentSearchInput.length > 0 && (
                <SearchSummary searchInput={currentSearchInput} />
              )}
              <TableOfContentsFloating />
            </>
          ) : (
            <Loader color="blue" size="xl" style={{ justify: 'center', marginTop: '20%' }} />
          )}
          <br />
          <br />
        </AppShell.Main>
      </AppShell>
    </InstantSearch>
  );
};

export default Search;
