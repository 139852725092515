import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mantine/core';
import PortailLayout from '../../component/Layout/PortailLayout';
import YearList from './components/YearList'; // Import du composant YearList
import DomainList from './components/DomainList'; // Import du composant DomainList

const DecisionsContainer = styled.div`
  padding: 2rem;
  margin-top: 5%;
  background-color: #f9fafb;
  min-height: 100vh;
`;

const Title = styled.h2`
  text-align: center;
  color: #1c7ed6;
  margin-bottom: 2rem;
  margin-top: 2.5rem;
`;

const SwitchButton = styled(Button)`
  display: block;
  margin: 0 auto 2rem auto;
`;

export function PortalDecisionsPage() {
  const [isYearMode, setYearMode] = useState(true);

  return (
    <PortailLayout>
      <DecisionsContainer>
        <Title>Explorez les Décisions Judiciaires</Title>
        <SwitchButton onClick={() => setYearMode(!isYearMode)}>
          {isYearMode ? "Afficher par Domaine" : "Afficher par Année"}
        </SwitchButton>

        {/* Appel des composants */}
        {isYearMode ? <YearList /> : <DomainList />}
      </DecisionsContainer>
    </PortailLayout>
  );
}

export default PortalDecisionsPage;
