import React from 'react';
import styled from 'styled-components';
import { Title, Text, ThemeIcon, Button } from '@mantine/core';
import { IconSearch, IconSettingsAutomation, IconBolt } from '@tabler/icons-react';
import searchInstantVideo from '../../../assets/video/search_instant.MP4';
import { useNavigate } from 'react-router-dom';
import PortailLayout from '../../../component/Layout/PortailLayout';
import { fonts } from '../../../component/themes';
const FullWidthBackground = styled.div`
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  padding: 3rem 1rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1.5rem;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  aspect-ratio: 1 / 1;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    opacity: 0.5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 700px;

  @media (max-width: 1024px) {
    max-width: 100%;
    text-align: center;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 2.8rem;
  font-weight: 700;
  font-family: ${fonts.heading};

  color: #38bdf8;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: 1.5rem;
  color: #cbd5e1;
  font-family: ${fonts.body};

  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  p {
    margin: 0;
    font-size: 1.1rem;
    color: #e2e8f0;
    font-family: ${fonts.body};

    font-weight: 500;
    line-height: 1.6;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`;

export const SearchInstant = ({showTitle=true}) => {
  const navigate = useNavigate();

  return (
    <FullWidthBackground>
      <ContentWrapper>
      {showTitle && <StyledTitle>Pourquoi perdre du temps à chercher ?</StyledTitle> }
        <StyledSubtitle>
          Accédez aux résultats instantanés grâce à une recherche intuitive qui vous permet de trouver des articles juridiques et des décisions en quelques secondes.
        </StyledSubtitle>

        <div>
          <IconTextWrapper>
            <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }}>
              <IconBolt size={24} />
            </ThemeIcon>
            <p>Obtenez des résultats dès les premières lettres saisies.</p>
          </IconTextWrapper>

          <IconTextWrapper>
            <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'teal', to: 'lime' }}>
              <IconSettingsAutomation size={24} />
            </ThemeIcon>
            <p>Appliquez des filtres clairs pour affiner rapidement votre recherche.</p>
          </IconTextWrapper>

          <IconTextWrapper>
            <ThemeIcon radius="xl" size="lg" variant="gradient" gradient={{ from: 'orange', to: 'red' }}>
              <IconSearch size={24} />
            </ThemeIcon>
            <p>Idéal pour les professionnels cherchant une solution efficace et rapide.</p>
          </IconTextWrapper>
        </div>

        <ButtonWrapper>
          <Button
            variant="gradient"
            gradient={{ from: 'indigo', to: 'cyan' }}
            size="lg"
            onClick={() => navigate('/login')}
          >
            Explorer Maintenant
          </Button>
        </ButtonWrapper>
      </ContentWrapper>

      <ImageWrapper>
        <video src={searchInstantVideo} autoPlay loop muted playsInline />
      </ImageWrapper>
    </FullWidthBackground>
  );
};

export const RechercheInstantPage = () => {
  return (
    <PortailLayout>
      <SearchInstant />
    </PortailLayout>
  );
};

export default SearchInstant;
