import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Group, Loader, Slider, Text, Button, Modal } from '@mantine/core';
import { db } from '../../config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { CodeContentPage } from '../../component/lawsContent';
import { IconTextSize, IconArrowLeft } from '@tabler/icons-react';
import PortailLayout from '../../component/Layout/PortailLayout';

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.3rem;

  @media (max-width: 768px) {
    align-items: stretch;
    margin-top: 1rem;
  }
`;

const StyledSlider = styled(Slider)`
  width: 120px;

  .mantine-Slider-mark {
    display: none;
  }

  .mantine-Slider-thumb {
    width: 10px;
    height: 10px;
    background-color: #0056b3;
  }

  .mantine-Slider-bar {
    background-color: #0056b3;
  }
`;

const BackLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1c7ed6;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 5rem;
  margin-left: 1rem;

  &:hover {
    color: #ff922b;
    transform: translateX(-5px);
    transition: color 0.3s ease, transform 0.2s ease;
  }

  @media (max-width: 768px) {
    margin-left: 0.5rem;
    margin-top: 5rem;
    font-size: 0.9rem;
  }
`;

export const PortalLawPageWithURL = () => {
    const { type, text } = useParams(); // Récupérer les paramètres de l'URL
    const navigate = useNavigate();

    const [docData, setDocData] = useState(null);
    const [fontSize, setFontSize] = useState(16); // Taille du texte
    const [isModalOpen, setIsModalOpen] = useState(false); // État pour l'overlay
    const loiList = ["code","actes-uniformes"]

    const fetchData = async (lawtype) => {
        try {
            const docRef = doc(db, 'doclist', 'doclist');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const docTemp = docSnap.data();
                setDocData(docTemp[lawtype]);
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Une erreur s\'est produite :', error);
        }
    };
    const getLabel = () => {
        if (type === 'code' || type === 'actes-uniformes' ) {
          return 'vers Lois et Règlements';
        } else if (type === 'convention-collective') {
          return 'vers Conventions collectives';
        } else if (type === 'convention-fiscale') {
          return 'vers Conventions fiscales';
        } else {
          return 'précédent'; // Valeur par défaut
        }
      };
    const returnPrevious = () => {
        if (loiList.includes(type)){
            navigate('/portail-lois-et-reglements')
        }else if (type ==="convention-fiscale"){
            navigate('/tax-agreements')
        }else if (type ==="convention-collective"){
            navigate('/collective-agreements')
        }else {
            navigate('/portail-lois-et-reglements')
        }
    };
    // Vérifier et mettre à jour le compteur dans localStorage
    const checkReadingLimitOnLoad = () => {
        const today = new Date().toISOString().split('T')[0]; // Date au format YYYY-MM-DD
        const storedData = JSON.parse(localStorage.getItem('readingTime')) || {};

        if (!storedData[today]) {
            storedData[today] = { timeSpent: 0 };
        }

        if (storedData[today].timeSpent >= 3 * 60) {
            setIsModalOpen(true); // Afficher l'overlay si la limite est dépassée
            return true; // Limite atteinte
        }

        localStorage.setItem('readingTime', JSON.stringify(storedData));
        return false; // Limite non atteinte
    };

    // Incrémenter directement dans localStorage toutes les **10 secondes**
    const incrementReadingTime = () => {
        const today = new Date().toISOString().split('T')[0];
        const storedData = JSON.parse(localStorage.getItem('readingTime')) || {};

        if (!storedData[today]) {
            storedData[today] = { timeSpent: 0 };
        }

        storedData[today].timeSpent += 1; // Ajouter **10 secondes**

        if (storedData[today].timeSpent >= 3 * 60) {
            setIsModalOpen(true); // Afficher l'overlay si la limite est dépassée
            return; // Interrompre l'incrémentation
        }

        localStorage.setItem('readingTime', JSON.stringify(storedData));
        console.log(`Temps passé aujourd'hui : ${storedData[today].timeSpent} secondes`);
    };


    useEffect(() => {
        if (type) { fetchData(type); if (checkReadingLimitOnLoad()) return; const intervalId = setInterval(() => incrementReadingTime(), 1000); return () => clearInterval(intervalId); }
    }, [type]);

    return (
        <PortailLayout>
            <div >

                {/* Lien de retour */}
                <BackLink onClick={() => returnPrevious()}>
                    <IconArrowLeft size={20} />
                    {`Retour ${getLabel()}`}
                </BackLink>

                {/* Modal pour inviter à se connecter ou s'inscrire */}
                <Modal opened={isModalOpen} onClose={() => navigate('/login')} centered>
                    <Text size="lg" weight={500} style={{ marginBottom: "1rem" }}>
                        Vous avez atteint votre limite de lecture quotidienne.
                    </Text>
                    <Group position="center">
                        <Button variant="light" color="blue" onClick={() => navigate('/login')}>
                            Se connecter </Button>
                        <Button variant="outline" color="blue" onClick={() => navigate('/register')}> S'inscrire </Button>
                    </Group>
                </Modal>

                {/* Sélection de la taille des caractères */}
                <div style={{ marginTop: "2rem" }}>{text && (<SliderContainer><Text size="xs" weight={500} style={{ display: "flex", alignItems: "center", gap: "8px" }}><IconTextSize size={14} /> {fontSize}px </Text><StyledSlider value={fontSize} onChange={setFontSize} min={12} max={30} step={1} /></SliderContainer>)}

                    <div>{docData && text ? (<CodeContentPage docdata={docData} texttype={type} currentId={text} fontSize={fontSize} />) : (<Group mt="md" mb="xs" justify="center"><Loader color="blue" size="xl" type="dots" /></Group>)}</div></div>
                    </div>   
        </PortailLayout>);
};
