import cx from 'clsx';
import { useState } from 'react';
import { Box, Text, Group, rem } from '@mantine/core';
import { IconListSearch } from '@tabler/icons-react';
import classes from './newToc.module.css';

export function TableOfContentsFloating(props) {
  const { links } = props;
  const [active, setActive] = useState(0);

  const handleClick = (event, index, item) => {
    event.preventDefault();
    setActive(index);

    // Scroll vers l'élément cible
    document.getElementById(item.link)?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });

    // Mettre à jour la position de l'indicateur
    document.documentElement.style.setProperty('--active-index', index);
  };

  const items = links.map((item, index) => (
    <Box
      component="a"
      href={item.link}
      key={item.label}
      onClick={(event) => handleClick(event, index, item)}
      className={cx(classes.link, { [classes.linkActive]: active === index })}
      style={{ paddingLeft: `calc(${item.order} * var(--mantine-spacing-md))`, textAlign: 'left' }}
    >
      {item.label}
    </Box>
  ));

  return (
    <div className={classes.root}>
      <Group mb="md">
        <IconListSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
        <Text>Table des matières</Text>
      </Group>
      <div className={classes.container}>
        <div
          className={classes.indicator}
          style={{
            transform: `translateY(calc(var(--link-height) * ${active}))`,
          }}
        />
        {items}
      </div>
    </div>
  );
}
