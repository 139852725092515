import React from 'react';
import styled from 'styled-components';
import { Text } from '@mantine/core';
import { IconSearch, IconBell, IconFolder, IconHeart } from '@tabler/icons-react';

const SectionTitle = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #0056b3;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const QuickActionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const StyledActionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: #0056b3;
  color: white;
  border-radius: 12px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: #003366;
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 480px) {
    padding: 1.2rem;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 1rem;

  & > svg {
    width: 40px;
    height: 40px;

    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
    }

    @media (max-width: 480px) {
      width: 32px;
      height: 32px;
    }
  }
`;

const ActionText = styled(Text)`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.95rem;
  }
`;

const QuickActions = () => (
  <>
    <SectionTitle>Actions rapides</SectionTitle>
    <QuickActionsContainer>
      <StyledActionButton onClick={() => (window.location.href = '/search')}>
        <IconWrapper>
          <IconSearch />
        </IconWrapper>
        <ActionText>Nouvelle recherche</ActionText>
      </StyledActionButton>
      <StyledActionButton onClick={() => (window.location.href = '/alertes-juridiques')}>
        <IconWrapper>
          <IconBell />
        </IconWrapper>
        <ActionText>Configurer des alertes</ActionText>
      </StyledActionButton>
      <StyledActionButton onClick={() => (window.location.href = '/dossiers')}>
        <IconWrapper>
          <IconFolder />
        </IconWrapper>
        <ActionText>Mes dossiers</ActionText>
      </StyledActionButton>
      <StyledActionButton onClick={() => (window.location.href = '/favoris')}>
        <IconWrapper>
          <IconHeart />
        </IconWrapper>
        <ActionText>Mes favoris</ActionText>
      </StyledActionButton>
    </QuickActionsContainer>
  </>
);

export default QuickActions;
