import React, { useState } from "react";
import styled from "styled-components";
import { Button, Title, Text } from "@mantine/core";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { IconAt, IconLock, IconUser, IconPhone, IconBrandGoogle, IconBrandFacebook } from "@tabler/icons-react";
import imageRegister from "../../assets/image_home.png";
import PortailLayout from "../../component/Layout/PortailLayout";
import { db } from "../../config/firebase-config";
const FullWidthBackground = styled.div`
  background: linear-gradient(to bottom, #f1f5f9, #e2e8f0);
  color: #1e293b;
  padding: 3rem 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${imageRegister});
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
  }
`;

const RegisterWrapper = styled.div`
  z-index: 2;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  max-width: 600px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #1e293b;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #475569;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const InputRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 1.5rem;

  input {
    width: 100%;
    padding: 0.75rem 2.5rem;
    border-radius: 8px;
    border: 1px solid ${({ isError }) => (isError ? "#ef4444" : "#3b82f6")};
    background: ${({ isError }) => (isError ? "#ffe4e6" : "#f8fafc")};
    color: #1e293b;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: ${({ isError }) => (isError ? "#ef4444" : "#3b82f6")};
      background: #ffffff;
      box-shadow: 0 0 8px rgba(59, 130, 246, 0.2);
    }
  }

  .input-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ isError }) => (isError ? "#ef4444" : "#3b82f6")};
    transition: transform 0.3s ease, color 0.3s ease;

    input:focus ~ & {
      transform: translateY(-50%) scale(1.2);
    }
  }
`;


const SocialButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Espace entre icône et texte */
  margin-top: 1rem;
  width: 100%;
  border-radius: 8px;
  font-size: 1rem; /* Taille du texte */
  transition: all 0.3s ease;

  background: ${({ color }) =>
    color === "blue" ? "#4285F4" : "#4267B2"}; /* Couleur par défaut */
  color: #ffffff;

  &:hover {
    background: ${({ color }) =>
      color === "blue" ? "#357ae8" : "#365899"}; /* Couleur au survol */
  }

  svg {
    font-size: 1.5rem; /* Taille de l'icône */
    color: #ffffff;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Centre verticalement */
`;



const ButtonWrapper = styled.div`
  margin-top: 1rem;

  button {
    background: ${({ isFormValid }) => (isFormValid ? "#22c55e" : "#3b82f6")};
    color: #ffffff;
    pointer-events: auto;
    transition: all 0.3s ease;

    &:hover {
      background: ${({ isFormValid }) => (isFormValid ? "#16a34a" : "#2563eb")};
    }
  }
`;

const LegalText = styled.div`
  font-size: 0.9rem;
  color: #475569;
  text-align: left;
  margin-top: 1rem;

  a {
    color: #3b82f6;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    all: unset;
    color: #3b82f6;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LinkText = styled(Text)`
  font-size: 0.9rem;
  color: #3b82f6;
  text-align: center;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export function Register({ signInWithGoogle, signInWithFacebook }) {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerpwd, setRegisterpwd] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [msgError, setMsgError] = useState("");
  const [attemptSubmit, setAttemptSubmit] = useState(false);
  const navigate = useNavigate();

  const isFormValid =
    firstName &&
    lastName &&
    phone &&
    registerEmail.includes("@") &&
    registerpwd.length >= 6;

    const handleSignUp = async (e) => {
        e.preventDefault();
        if (!isFormValid) {
          setAttemptSubmit(true);
          return;
        }
      
        createUserWithEmailAndPassword(auth, registerEmail, registerpwd)
          .then(async (user) => {
            const authInfo = {
              userID: user.user.uid,
              name: `${firstName} ${lastName}`,
              phone,
              profilePhoto: null,
              isAuth: true,
              email:registerEmail,
              emailVerified: user.user.emailVerified,
            };
      
            // Stocker l'utilisateur dans localStorage
            localStorage.setItem("auth", JSON.stringify(authInfo));
      
            // Ajouter l'utilisateur dans Firestore
            try {
              const userDocRef = doc(db, "users", user.user.uid); // Utiliser l'UID comme ID du document
              await setDoc(userDocRef, {
                created: serverTimestamp(),
                uid: user.user.uid,
                userinfo: {
                  profile: {
                    name: `${firstName} ${lastName}`,
                    email: registerEmail,
                    phone: phone || "",
                    picture: user.user.photoURL || "", // Ajouter la photo de profil si disponible
                  },
                  metadata: {
                    lastLoginAt: user.user.metadata.lastSignInTime,
                    creationTime: user.user.metadata.creationTime,
                  },
                },
                customization: {
                  color: "#10325c",
                  themecolor: "#0f6ce6",
                  darkmode: false,
                  widemode: false,
                },
                preferences: [], // Préférences vides par défaut
                online: true, // Statut de connexion initial
              });
      
              console.log(`Utilisateur ${user.user.uid} enregistré dans Firestore.`);
            } catch (firestoreError) {
              console.error("Erreur lors de l'enregistrement dans Firestore:", firestoreError);
            }
      
            // Vérifier l'état de vérification de l'email
            if (auth.currentUser && !auth.currentUser.emailVerified) {
              navigate("/email-verification");
              console.log("Email non vérifié");
            } else {
              console.log("Email vérifié");
              navigate("/home");
            }
          })
          .catch((error) => {
            switch (error.code) {
              case "auth/email-already-in-use":
                setMsgError("Vous êtes déjà inscrit");
                break;
              case "auth/weak-password":
                setMsgError("Mot de passe trop faible");
                break;
              default:
                setMsgError(error.message);
            }
            setTimeout(() => setMsgError(""), 3000);
          });
      };
      

  return (
    <PortailLayout>
      <FullWidthBackground>
        <RegisterWrapper>
          <StyledTitle>Créer un compte</StyledTitle>
          <StyledSubtitle>
            Inscrivez-vous pour accéder à nos outils avancés.
          </StyledSubtitle>
          {msgError && <Text color="red">{msgError}</Text>}

          <InputRow>
            <InputWrapper isError={attemptSubmit && !firstName}>
              <input
                placeholder="Prénom"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <IconUser className="input-icon" />
            </InputWrapper>
            <InputWrapper isError={attemptSubmit && !lastName}>
              <input
                placeholder="Nom"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <IconUser className="input-icon" />
            </InputWrapper>
          </InputRow>

          <InputWrapper isError={attemptSubmit && !phone}>
            <input
              placeholder="Numéro de téléphone (indicatif inclus)"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <IconPhone className="input-icon" />
          </InputWrapper>

          <InputWrapper isError={attemptSubmit && !registerEmail.includes("@")}>
            <input
              placeholder="Adresse email"
              type="email"
              value={registerEmail}
              onChange={(e) => setRegisterEmail(e.target.value)}
            />
            <IconAt className="input-icon" />
          </InputWrapper>
          <InputWrapper isError={attemptSubmit && registerpwd.length < 6}>
            <input
              placeholder="Mot de passe"
              type="password"
              value={registerpwd}
              onChange={(e) => setRegisterpwd(e.target.value)}
            />
            <IconLock className="input-icon" />
          </InputWrapper>

          <ButtonWrapper isFormValid={isFormValid}>
            <Button
              fullWidth
              onClick={handleSignUp}
            >
              S'inscrire
            </Button>
          </ButtonWrapper>

          <SocialButton
                variant="outline"
                color="blue"
                onClick={signInWithGoogle}
                >
                <IconWrapper>
                    <IconBrandGoogle />
                </IconWrapper>
                Continuer avec Google
                </SocialButton>

                <SocialButton
                variant="outline"
                color="indigo"
                onClick={signInWithFacebook}
                >
                <IconWrapper>
                    <IconBrandFacebook />
                </IconWrapper>
                Continuer avec Facebook
                </SocialButton>

                <LegalText>
                    En vous inscrivant, vous acceptez nos{" "}
                    <button onClick={() => navigate("/terms")}>Conditions d'utilisation</button>{" "}
                    et notre{" "}
                    <button onClick={() => navigate("/privacy")}>Politique de confidentialité</button>.
                    </LegalText>
          <LinkText onClick={() => navigate("/login")}>
            Déjà inscrit ? Connectez-vous ici.
          </LinkText>
        </RegisterWrapper>
      </FullWidthBackground>
    </PortailLayout>
  );
}

export default Register;
