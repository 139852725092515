import React from 'react';
import styled from 'styled-components';
import { Title, Text, ThemeIcon, Button } from '@mantine/core';
import { IconBell, IconSettings, IconMail } from '@tabler/icons-react';
import alertesVideo from '../../../assets/video/search_instant.MP4'; // Vidéo spécifique pour les alertes juridiques
import PortailLayout from '../../../component/Layout/PortailLayout';
import { useNavigate } from 'react-router-dom';
import { fonts } from '../../../component/themes';
const FullWidthBackground = styled.div`
  background: linear-gradient(135deg, #1e293b, #0f172a);
  color: #ffffff;
  padding: 2rem 1rem;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 1.5rem 1rem;
    gap: 1rem;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  position: relative;
  max-width: 400px;
  aspect-ratio: 1 / 1; /* Ratio carré */
  overflow: hidden;
  transform: translate(0, 0);
  z-index: 1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    opacity: 0.5;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    transform: none;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 500px;
  z-index: 2;

  @media (max-width: 1024px) {
    max-width: 100%;
    text-align: center;
  }
  @media (max-width: 768px) {
    margin-top: 10%;
    text-align: center;
  }
`;

const StyledTitle = styled(Title)`
  font-size: 2rem;
  font-weight: 700;
  font-family: ${fonts.heading};
  color: #38bdf8;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const StyledSubtitle = styled(Text)`
  font-size: 1rem;
  color: #cbd5e1;
  font-family: ${fonts.body};
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
`;

const IconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;

  p {
    margin: 0;
    font-size: 0.9rem;
    font-family: ${fonts.body};
    color: #e2e8f0;
    font-weight: 500;
    line-height: 1.4;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 1.5rem;

  button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
  }
`;

export function AlertesJuridiquesPage() {
  const navigate = useNavigate();

  return (
    <PortailLayout>
      <FullWidthBackground>
        {/* Contenu principal */}
        <ContentWrapper>
          <StyledTitle>Restez informé des évolutions juridiques</StyledTitle>
          <StyledSubtitle>
            Recevez des alertes personnalisées sur les nouvelles décisions, lois ou sujets qui vous intéressent.
          </StyledSubtitle>

          <div>
            <IconTextWrapper>
              <ThemeIcon radius="xl" size="md" variant="gradient" gradient={{ from: 'blue', to: 'cyan' }}>
                <IconBell size={20} />
              </ThemeIcon>
              <p>Notifications en temps réel sur les changements juridiques.</p>
            </IconTextWrapper>

            <IconTextWrapper>
              <ThemeIcon radius="xl" size="md" variant="gradient" gradient={{ from: 'teal', to: 'lime' }}>
                <IconSettings size={20} />
              </ThemeIcon>
              <p>Personnalisez vos alertes selon vos domaines d'intérêt.</p>
            </IconTextWrapper>

            <IconTextWrapper>
              <ThemeIcon radius="xl" size="md" variant="gradient" gradient={{ from: 'orange', to: 'red' }}>
                <IconMail size={20} />
              </ThemeIcon>
              <p>Recevez des mises à jour détaillées directement dans votre boîte mail.</p>
            </IconTextWrapper>
          </div>

          <ButtonWrapper>
            <Button
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan' }}
              size="md"
              onClick={() => navigate('/explore')}
            >
              Activer les alertes
            </Button>
          </ButtonWrapper>
        </ContentWrapper>

        {/* Vidéo */}
        <ImageWrapper>
          <video src={alertesVideo} autoPlay loop muted playsInline />
        </ImageWrapper>
      </FullWidthBackground>
    </PortailLayout>
  );
}

export default AlertesJuridiquesPage;
