import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase-config'; // Assurez-vous que votre config Firebase est correcte
import PortailLayout from '../component/Layout/PortailLayout';
import { IconBook2 } from '@tabler/icons-react'; // Icônes pour les collections
import { Loader, Center } from '@mantine/core';

const collectionsInfo = [
  {
    id: 'convention-collective',
    name: 'Conventions collectives',
    icon: <IconBook2 size={24} color="#1c7ed6" />,
  },
];

// Styled Components
const LawsContainer = styled.div`
  padding: 2rem;
  margin-top: 5%;
  background-color: #f9fafb;
  min-height: 100vh;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;

  h3 {
    color: #495057;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Trois colonnes par défaut */
  gap: 1.5rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes sur tablette */
    gap: 1rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* Une colonne sur mobile */
    gap: 0.75rem;
  }
`;

const LawItem = styled(Link)`
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: none;
  color: #000; /* Texte noir par défaut */
  transition: transform 0.2s ease, color 0.2s ease;
  text-align: left; /* Alignement à gauche */
  
  &:hover {
    color: #1c7ed6; /* Texte bleu au survol */
    transform: scale(1.05);
  }

  margin-bottom: 0.5rem; /* Espacement entre les lignes */
`;

export function PortalTaxConventionsPage() {
  const [collectionsData, setCollectionsData] = useState({}); // Stocke les données de toutes les collections
  const [isLoading, setIsLoading] = useState(true); // État pour le loader

  // Récupérer les données depuis Firestore pour chaque collection
  useEffect(() => {
    const fetchAllCollections = async () => {
      const data = {};

      for (const collectionInfo of collectionsInfo) {
        try {
          const snapshot = await getDocs(collection(db, collectionInfo.id));
          // Inverser l'ordre des documents récupérés
          data[collectionInfo.id] = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .reverse(); // Inverser l'ordre des textes
        } catch (error) {
          console.error(`Erreur lors de la récupération des données pour ${collectionInfo.name}:`, error);
        }
      }

      setCollectionsData(data);
      setIsLoading(false); // Désactiver le loader après récupération des données
    };

    fetchAllCollections();
  }, []);

  return (
    <PortailLayout>
      {/* Titre SEO */}
      <title>Portail des conventions fiscales - Consultez et Explorez les conventions fiscales du sénégal</title>

      <LawsContainer>
        {/* Titre descriptif */}
        <h2 style={{ textAlign: 'center', color: '#1c7ed6', marginBottom: '2rem',marginTop: '2.5rem' }}>
          Explorez les conventions fiscales du sénégal
        </h2>

        {/* Loader */}
        {isLoading ? (
          <Center style={{ minHeight: '50vh' }}>
            <Loader size="lg" />
          </Center>
        ) : (
          collectionsInfo.map((collectionInfo) => (
            <div key={collectionInfo.id} style={{ marginBottom: '2rem' }}>
              {/* Titre de la section */}
              <SectionTitle>
                {collectionInfo.icon}
                <h3>{collectionInfo.name}</h3>
              </SectionTitle>

              {/* Liste des lois/règlements */}
              <GridContainer>
                {collectionsData[collectionInfo.id]?.length > 0 ? (
                  collectionsData[collectionInfo.id].map((law) => (
                    <LawItem key={law.id} to={`/portallaws/${collectionInfo.id}/${law.id}`}>
                      {law.law_text}
                    </LawItem>
                  ))
                ) : (
                  <p style={{ color: '#868e96', textAlign: 'left' }}>Aucun texte disponible pour le moment.</p>
                )}
              </GridContainer>
            </div>
          ))
        )}
      </LawsContainer>
    </PortailLayout>
  );
}

export default PortalTaxConventionsPage;


